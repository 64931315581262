/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { TabsTabItem } from "../TabsTabItem";
import "./style.css";
import { BubbleInfo } from "../BubbleInfo";
import { HeaderMobile } from "../HeaderMobile";
import { CardAjoutDUne } from "../CardAjoutDUne";
import { CardProgrammerUne } from "../CardProgrammerUne";
import { InformationDate } from "../InformationDate";
import { IconArrowArrow } from "../IconArrowArrow";
import { ChatMessage } from "../ChatMessage/ChatMessage";
import {CardRdv} from '../CardRdv';
import { ButtonEvaluation } from "../../../icons/ButtonEvaluation";
import { IconSupportLike } from "../../Patients/IconSupportLike";
import { Group5 } from "../../../icons/Group5";
import { Group6 } from "../../../icons/Group6";
import { State } from "../../Patients/State";
import axios from "axios";
import { api_url } from "../../../globarvariable";
import { useNavigate } from "react-router-dom";
import ModalCentered from "../Modal/Modal";
import { Group51 } from "../../../icons/Group51/Group51";

interface Props {
  property1: "variant-2" | "default";
  className: any;
  lineClassName: any;
  line: string;
  tabsTabItemFrameClassName: any;
  tabsTabItemStateActiveClassName: any;
  tabsTabItemLineClassName: any;
  tabsTabItemLine: string;
  tabsTabItemFrameClassNameOverride: any;
  tabsTabItemStateActiveClassNameOverride: any;
  tabsTabItemLineClassNameOverride: any;
}

export const TabsTabs = ({
  property1,
  className,
  lineClassName,
  line = "/img/line-1-1.svg",
  tabsTabItemFrameClassName,
  tabsTabItemStateActiveClassName,
  tabsTabItemLineClassName,
  tabsTabItemLine = "/img/line-2-5.svg",
  tabsTabItemFrameClassNameOverride,
  tabsTabItemStateActiveClassNameOverride,
  tabsTabItemLineClassNameOverride,
}: Props): JSX.Element => {

  const [tabActive, setTabActive] = useState<any>('default');
  const [sessionDate, setSessionDate] = useState([]);
  const [hour, setHour] = useState([])
  const accessToken = localStorage.getItem('accessToken');
  const [nameDoctor, setNameDoctor] = useState('');
  const [addressDoctor, setAddressDoctor] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [prescriptionId, setPrescriptionId] = useState('');
  const [namePatient, setNamePatient] = useState('');
  const [centerName, setCenterName] = useState('');
  const [centerAddress, setCenterAddress] = useState('');
  const [centerPostal, setCenterPostal] = useState('');
  const [objectifsetted, setObjectifSetted] = useState(false);
  const [objectif, setObjectif] = useState('');
  const [objectifDescription, setObjectifDescription] = useState('');
  const [isQRAvailable, setIsQRAvailable] = useState(false);
  const [isReportingAvailable, setIsReportingAvailable] = useState(false);
  const [reporting, setReporting] = useState([]);
  const [formattedDate, setFormattedDate] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [datePrescription, setDatePrescription] = useState('');
  const [activities, setActivities] = useState([]);
  const [today, setToday] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showQuestion ,setShowQuestion] = useState(false);
  const [showQuestionC ,setShowQuestionC] = useState(false);
  const [showSeanceMissed, setShowSeanceMissed] = useState(true);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [textSelectedGoal, setTextSelectedGoal] = useState('');
  const [showButton ,setShowButton] = useState(true);

  const navigate = useNavigate();

  const handleTabObservanceActive = () => {
    if(tabActive === "default"){
      setTabActive('default');
      localStorage.setItem('screen', 'default');
    } 
    else if(tabActive === "scheduled"){
      setTabActive('scheduled');
      localStorage.setItem('screen', 'scheduled');
    }
    else if(tabActive ==='session-done') {
      setTabActive('session-done');
      localStorage.setItem('screen', 'session-done');
    }
    else if(tabActive ==='variant-2'){
      setTabActive(localStorage.getItem('screen'));
    }
          
  }

  const handleTabChatActive = () => {
      setTabActive('variant-2');
  }

  const formatDate = (dateString) => {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Extract the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    // Format the date part as 'YYYY-MM-DD'
    const formattedDate = `${day}/${month}`;

    return formattedDate;
  };

  const formatHour = (dateString) => {
    // Create a Date object from the input string
    const date = dateString.split('T');
    const hour = date[1].split(':');

    // Format the date part as 'YYYY-MM-DD'
    const formattedHour = `${hour[0]}:${hour[1]}`;

    return formattedHour;
  };

  const navigateToGoal = () => {
    navigate('/goal');
  }

  useEffect(() => {
    const currentDate = new Date();
    const options = { year: 'numeric', month: 'long', day: '2-digit' };

    const formatted = currentDate.toLocaleDateString('fr-FR', options);

    setFormattedDate(formatted);

  })

  useEffect(() => {
    const fetchData = () => {
      const response =  axios.get(api_url+'api/appointments/my', {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response =>{
        const dataString = JSON.stringify(response.data.appointments);
        const dataArray  = JSON.parse(dataString);
        
          if(dataArray.length != 0 && dataArray[0].first === true){
              setTabActive('scheduled');
              localStorage.setItem('screen', 'scheduled');
              const test : any = [];
              
              const dateArray = dataArray.map(item => item.date);
              setToday(dateArray[0]);
              const filteredDataArray = dataArray.filter(item => {
                const today = new Date();
                const itemDate = new Date(item.date);
                return itemDate.getDate() !== today.getDate() ||
                        itemDate.getMonth() !== today.getMonth() ||
                        itemDate.getFullYear() !== today.getFullYear();
               });
               const updatedDateArray = filteredDataArray.map(item => item.date);
              setSessionDate(updatedDateArray);
              
              const hourArray = dataArray.map(item => item.time);
              setHour(hourArray);
              const activity = dataArray.map(item => item.activities);
              setActivities(activity[0]);
          }
          else if(dataArray.length != 0 && dataArray[0].first === false){
            setTabActive('session-done');
            localStorage.setItem('screen', 'session-done');
            const test : any = [];
            
            const dateArray = dataArray.map(item => item.date);
            setToday(dateArray[0]);
              const filteredDataArray = dataArray.filter(item => {
                const today = new Date();
                const itemDate = new Date(item.date);
                return itemDate.getDate() !== today.getDate() ||
                        itemDate.getMonth() !== today.getMonth() ||
                        itemDate.getFullYear() !== today.getFullYear();
               });
            const updatedDateArray = filteredDataArray.map(item => item.date);
              setSessionDate(updatedDateArray);
            const hourArray = dataArray.map(item => item.time);
            setHour(hourArray);
            const activity = dataArray.map(item => item.activities);
            setActivities(activity[0]);
          }


          const resp = axios.get(api_url+'api/goals/my', {
            headers: {
              'Content-Type': 'application/json',
               Authorization: `Bearer ${accessToken}`,
            },
          }).then((response) =>{
            if(response.data.id){
              setObjectifSetted(true);
              setObjectif(response.data.name);
              setObjectifDescription(response.data.description);
            }

          })

      }).catch(error => {
        // Handle errors, including 404
        if (error.response) {
          localStorage.setItem('screen', 'default');
        } 
      });

      
      //
    }


    fetchData();

  }, []);

  useEffect(() => {
    const fetchData = () => {
      const response =  axios.get(api_url+'api/prescriptions/my', {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) =>{
         if(response.data.id){
              setPrescriptionId(response.data.id);
              setNamePatient(response.data.patient.first_name + response.data.patient.last_name)
              setNameDoctor(response.data.doctor.first_name +' '+ response.data.doctor.last_name)
              setAddressDoctor(response.data.doctor.address)
              setPostalCode(response.data.doctor.postal_code)
              setCity(response.data.doctor.city)
              setCenterName(response.data.patient.center.name)
              setCenterAddress(response.data.patient.center.address)
              setCenterPostal(response.data.patient.center.postal_code +' '+response.data.patient.center.city)

              const currentDate = new Date(response.data.patient.latest_prescription.created_at);
              const options = { year: 'numeric', month: 'long', day: '2-digit' };

              const formatted = currentDate.toLocaleDateString('fr-FR', options);
              setDatePrescription(formatted);
              
            }
      })
      //
    }


    fetchData();

  }, []);

  useEffect(() => {

  fetch(api_url+'api/appointments/my/today', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      }).then((response) => {
        if (!response.ok) {
          // Handle non-successful responses (e.g., 404, 500, etc.)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob()})
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        setIsQRAvailable(true);
        setImageUrl(url);

      })
      .catch((error) => {
        if (error.message.includes("HTTP error! Status: 404")) {
          // Handle 404 error specifically
          setIsQRAvailable(false); // Set some state to indicate QR is not available
        } else {
          // Handle other errors
          localStorage.setItem('screen', 'default');
          console.error('Error fetching image:', error);
        }
      });
  
  }, []);


  useEffect(() => {
    const fetchData = () => {
      const response =  axios.get(api_url+'api/ratings/my', {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response =>{
        const dataString = JSON.stringify(response.data.ratings);
        const dataArray  = JSON.parse(dataString);
        if(dataArray.length != 0){
          setReporting(dataArray);
        }
      }).catch(error => {
        // Handle errors, including 404
        if (error.response) {
          localStorage.setItem('screen', 'default');
        } 
      });
      //
    }


    fetchData();

  }, []);

  useEffect(() => {
    const resp = axios.get(api_url+'api/goals/my', {
      headers: {
        'Content-Type': 'application/json',
         Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) =>{
      if(response.data.id){
        setObjectifSetted(true);
        setObjectif(response.data.name);
        setObjectifDescription(response.data.description);
      }

    })
  }, []);


  const downloadPrescription = async () => {
    const response = await fetch(api_url+'api/prescriptions/'+prescriptionId+'/download', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', namePatient+'.pdf');
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error('Error downloading PDF:', error);
    });


  }

  //handle Yes rating button

  const handleYesRatingButton = (rating_id) => {
    const formData = {
      rate: 'oui',
    };

    fetch(api_url+'api/ratings/'+rating_id+'/rate', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json())
    .then((data) => {
        if(data.id){
            setShowModal(true)
        }
    })
  }

  //handle No rating buttons 

  const handleNoRatingButton = () => {
      setShowButton(false);
      setShowQuestion(true);
  }

  //handle Yes or No low index button

  const handleButtonLowIndexButton = (rating_id, value) => {
    const formData = {
      rate: value,
    };

    fetch(api_url+'api/ratings/'+rating_id+'/rate', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json())
    .then((data) => {
        if(data.id){
            setShowModal(true)
        }
    })
  }

  const handleNoSessionMissingRatingButton = () => {
       
  }

const onClose = () => {
    setShowModal(false)
    window.location.reload();
  }
 
  const handleChooseGoal = (key,e) => {
    if(selectedGoal === null){
      setSelectedGoal( key === selectedGoal ? null : key);
    }
    else{
      setSelectedGoal( key === selectedGoal ? 0 : key);
    }   
    
    setTextSelectedGoal(e.currentTarget.textContent);
  }

  const handleChooseC = (key,e) => {
    if(selectedGoal === null){
      setSelectedGoal( key === selectedGoal ? null : key);
    }
    else{
      setSelectedGoal( key === selectedGoal ? 0 : key);
    }   
    setTextSelectedGoal(e.currentTarget.textContent);
    setShowSeanceMissed(false);
    setShowQuestionC(true);
  }


  // handle Send response when clicking No

  const handleSendResponse = (rating_id) => {
    const formData = {
      rate: 'non,'+textSelectedGoal,
    };

    fetch(api_url+'api/ratings/'+rating_id+'/rate', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json())
    .then((data) => {
        if(data.id){
            setShowModal(true)
        }
    })
  }

  return (
    <div className={`tabs-tabs ${className}`}>
      <div className="overlap-group">
        <img className={`line-2 ${lineClassName}`} alt="Line" src={line} />
        <TabsTabItem
          className={tabsTabItemStateActiveClassName}
          frameClassName={tabsTabItemFrameClassName}
          line={tabsTabItemLine}
          lineClassName={tabsTabItemLineClassName}
          state={tabActive === "default" || tabActive === "scheduled" || tabActive ==='session-done' ? "active" : "inactive"}
          text="Observance"
          onClick = {() => handleTabObservanceActive()}
        />
        <TabsTabItem
          className={tabsTabItemStateActiveClassNameOverride}
          frameClassName={tabsTabItemFrameClassNameOverride}
          line={tabActive === "variant-2" ? "/img/line-2-6.svg" : undefined}
          lineClassName={tabsTabItemLineClassNameOverride}
          state={tabActive === "default" || tabActive === "scheduled"  || tabActive ==='session-done' ? "inactive" : "active"}
          text="Messages"
          onClick = {() => handleTabChatActive()}
        />
      </div>
      { tabActive === "default" &&
      <div className="default-screen">
        {objectifsetted}
        {objectifsetted === false &&  <div className="welcome-apaora">
          <div className="welcome">Bienvenue sur Apaora !</div>
        <p className="welcome-subtitle">Vous retrouverez ici tout ce dont vous aurez besoin dans votre parcours Activité Physique Adaptée.</p>
        <p className="define-goal"><button className="btn btn-primary" onClick={navigateToGoal}>Définir mon objectif</button></p>
        <IconArrowArrow className="icon-arrow-arrow-down" />
        </div>
        
        }
        {objectifsetted === true && <div className='confirm-goal'>
          <p className='heart-circle'><img src='/img/heart-circle-black.png' /></p>
          <h6>Votre objectif est :</h6>
          <h4 className='goal-confirmed'>{objectif}</h4>
          </div>
          }

          {isQRAvailable === true &&
            <>
                    <InformationDate
                    className="information-date-instance"
                    img="/img/line-4.svg"
                    line="/img/line-3.svg"
                    lineClassName="information-date-3"
                    text={formattedDate}
                  />

            <CardRdv className="card-RDV-instance" vector="/img/vector-1.svg"  date={formatDate(today)} hour={formatHour(today)} activities={activities} name={centerName} address={centerAddress} postal={centerPostal}/>

            <div className='qr-code'>
              <p className="text">Validez votre présence à la séance du {formattedDate} avec ce code</p>
              <p className="image"><img src={imageUrl} /></p>
            </div>
            </>
          }

          {
            reporting.length != 0 && reporting.map((data, item) => 

            data.type !== "no_appointment_scheduled"  && data.type !== "missed_appointment"  && data.type !== 'patient_index_low' &&
           <div className="card-survey">
            <div className="title-wrapper">
              <div className="title-evaluation">
                <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
                <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
              </div>
            </div>
            <div className="question">
              <p className="text-wrapper-14">{data.question}</p>
              <div className="evaluation">
                <ButtonEvaluation className="button-evaluation" rating_id={data.id}/>
                <Group5 className="group" rating_id={data.id}/>
                <Group6 fill="#84D038"  className="group-6" rating_id={data.id}/>
                <Group51 className="type-bien-state-active" rating_id={data.id} />
              </div>
            </div>
          </div>
            )
          }

          {       
            reporting.length != 0 && reporting.map((data, item) => 

            data.type === "no_appointment_scheduled" &&

          <div className="card-survey">
            <div className="title-wrapper">
              <div className="title-evaluation">
                <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
                <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
              </div>
            </div>
            <div className="question">
              <p className="text-wrapper-14">{data.question}</p>
              <div className="evaluation">
                {showButton === true && <button className="btn btn-primary yes" onClick={() => handleYesRatingButton(data.id)}>Oui</button> }
                {showButton === true &&  <button className="btn btn-primary no" onClick={handleNoRatingButton}>Non</button> }
              </div>
              {showQuestion === true && 
                <div className="question-no">
                  <ul>
                  <li className={`${selectedGoal == 1 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(1, e)}>{selectedGoal != 1? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je n'ai pas eu le temps </li>
                  <li className={`${selectedGoal == 2 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(2, e)}>{selectedGoal != 2 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je n'ai pas réussi à joindre le centre </li>
                  <li className={`${selectedGoal == 3 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(3, e)}>{selectedGoal != 3 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Le centre ne me propose pas de crénaux disponibles</li>
                  </ul>
                  <input type="text" className="other" placeholder='Autre' />
                  <p><button className="btn btn-primary send" onClick={() => handleSendResponse(data.id)}>Envoyer</button></p>
                </div>
              }
            </div>
          </div>

            )
          }

{
            reporting.length != 0 && reporting.map((data, item) => 
            data.type === "patient_index_low" &&
           <div className="card-survey">
             <div className="title-wrapper">
               <div className="title-evaluation">
                 <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
                 <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
               </div>
             </div>
             <div className="question">
               <p className="text-wrapper-14">{data.question}</p>
               <div className="evaluation">
               {showButton === true && <button className="btn btn-primary yes" onClick={() => handleButtonLowIndexButton(data.id, 'oui')}>Oui</button> }
                {showButton === true &&  <button className="btn btn-primary no" onClick={handleButtonLowIndexButton(data.id, 'non')}>Non</button> }
               </div>
             </div>
           </div>
            )
          }

        {
          reporting.length != 0 && reporting.map((data, item) => 
         data.type === "missed_appointment"  && 
           <div className="card-survey">
           <div className="title-wrapper">
             <div className="title-evaluation">
               <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
               <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
             </div>
           </div>
           <div className="question">
             <p className="text-wrapper-14">{data.question}</p>
             {showSeanceMissed === true && 
             <div className="evaluation">
             <ul className="session-missing">
                <li className={`${selectedGoal == 1 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(1, e)}>{selectedGoal != 1? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } C'est une erreur j'y suis allé </li>
                <li className={`${selectedGoal == 2 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(2, e)}>{selectedGoal != 2 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } J'ai eu un empêchement mais j'irai à la prochaine séance </li>
                <li className={`${selectedGoal == 3 && 'active'}`} key='1' onClick={(e) => handleChooseC(3, e)}>{selectedGoal != 3 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je ne suis plus motivé(e) et j'ai envie d'abandonner </li>
                </ul>
                <p><button className="btn btn-primary send" onClick={() => handleSendResponse(data.id)}>Envoyer</button></p>
             </div>
            }
             {showQuestionC === true && 
              <div className="question-no">
                 <p className="help">Avez-vous besoin d'aide pour continuer votre programme d'activité physique adaptée ?</p>
                <p>
                  <button className="btn btn-primary yes" onClick={() => handleYesRatingButton(data.id)}>Oui</button>
                  <button className="btn btn-primary no" onClick={handleNoSessionMissingRatingButton}>Non</button>
                </p>
              </div>
            }
           </div>
         </div>
      )
    }

          {sessionDate.map((date, item) => 

          <div className="session-list">
          <InformationDate
            className="information-date-instance"
            img="/img/line-4.svg"
            line="/img/line-3.svg"
            lineClassName="information-date-3"
            text=""
          />
          <CardRdv className="card-RDV-instance" vector="/img/vector-1.svg"  date={formatDate(date)} hour={formatHour(date)} activities={activities} name={centerName} address={centerAddress} postal={centerPostal}/>
          
          </div>
          )}

        
        <div className="frame-3 default">
          <InformationDate
            className="information-date-instance"
            img="/img/line-4.svg"
            line="/img/line-3.svg"
            lineClassName="information-date-3"
            lunMaiClassName="information-date-2"
            text={datePrescription}
          />
          <CardProgrammerUne  name={centerName} address={centerAddress} postal={centerPostal}/>
          <CardAjoutDUne ellipse="/img/ellipse-35.png"  name={nameDoctor} address={addressDoctor} postal_code={postalCode} city={city} dowloadPrescription={downloadPrescription}/>
        </div>
        <HeaderMobile
          className="header-mobile-instance"
          iconTimeCalendarIconTimeCalendarClassName="header-mobile-2"
          logoPropertyDefault="/img/logo.svg"
          type="logo-calendar-profile"
        />
        <BubbleInfo
          buttonBubbleIconEssentialEmojiIconEssentialEmojiClassName="bubble-info-3"
          buttonBubbleIconEssentialShareWrapperIconEssentialShareClassName="bubble-info-5"
          buttonBubblePropertyDefaultClassName="bubble-info-2"
          buttonBubblePropertyDefaultClassNameOverride="bubble-info-4"
          className="bubble-info-instance"
          type="variant-2"
        />
        </div>
      }

      { tabActive === "variant-2" &&
      <div>
        <ChatMessage className="chat-message-instance" state="unread" />
        <HeaderMobile
          className="header-mobile-instance"
          iconTimeCalendarIconTimeCalendarClassName="header-mobile-2"
          logoPropertyDefault="/img/logo.svg"
          type="logo-calendar-profile"
        />
        </div>
      }

      { tabActive === "scheduled" &&
          <div>
            {objectifsetted === false &&
            <div className="first-session">
              <p className="text-wrapper-5">Votre première séance est programmée.</p>
              <p className="p">Pour chaque séance, nous vous enverrons un rappel ici.</p>
              <button className="btn btn-primary" onClick={navigateToGoal}>Définir mon objectif</button>
            </div>
}
            
            {objectifsetted === true && <div className='confirm-goal'>
          <p className='heart-circle'><img src='/img/heart-circle-black.png' /></p>
          <h6>Votre objectif est :</h6>
          <h4 className='goal-confirmed'>{objectif}</h4>
          </div>
          }

          {isQRAvailable === true &&
          <>
              <InformationDate
              className="information-date-instance"
              img="/img/line-4.svg"
              line="/img/line-3.svg"
              lineClassName="information-date-3"
              text={formattedDate}
            />

          <CardRdv className="card-RDV-instance" vector="/img/vector-1.svg"  date={formatDate(today)} hour={formatHour(today)} activities={activities} name={centerName} address={centerAddress} postal={centerPostal}/>

            <div className='qr-code'>

              <p className="text">Validez votre présence à la séance du {formattedDate} avec ce code</p>
              <p className="image"><img src={imageUrl} /></p>
            </div>
            </>
          }
           {
            reporting.length != 0 && reporting.map((data, item) => 
            data.type !== "no_appointment_scheduled"  && data.type !== "missed_appointment" && data.type !== 'patient_index_low' &&
            <div className="card-survey">
             <div className="title-wrapper">
               <div className="title-evaluation">
                 <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
                 <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
               </div>
             </div>
             <div className="question">
               <p className="text-wrapper-14">{data.question}</p>
               <div className="evaluation">
                 <ButtonEvaluation className="button-evaluation" rating_id={data.id}/>
                 <Group5 className="group" rating_id={data.id}/>
                 <Group6 fill="#84D038" className="group-6"  rating_id={data.id}/>
                 <Group51 className="type-bien-state-active" rating_id={data.id}/>
               </div>
             </div>
           </div>
 
            )}
 
       {
          reporting.length != 0 && reporting.map((data, item) => 
         data.type === "no_appointment_scheduled"  && 

           <div className="card-survey">
             <div className="title-wrapper">
               <div className="title-evaluation">
                 <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
                 <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
               </div>
             </div>
             <div className="question">
               <p className="text-wrapper-14">{data.question}</p>
               <div className="evaluation">
               {showButton === true && <button className="btn btn-primary yes" onClick={() => handleYesRatingButton(data.id)}>Oui</button> }
                {showButton === true &&  <button className="btn btn-primary no" onClick={handleNoRatingButton}>Non</button> }
               </div>
               {showQuestion === true && 
                <div className="question-no">
                  <ul>
                  <li className={`${selectedGoal == 1 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(1, e)}>{selectedGoal != 1? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je n'ai pas eu le temps </li>
                  <li className={`${selectedGoal == 2 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(2, e)}>{selectedGoal != 2 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je n'ai pas réussi à joindre le centre </li>
                  <li className={`${selectedGoal == 3 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(3, e)}>{selectedGoal != 3 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Le centre ne me propose pas de crénaux disponibles</li>
                  </ul>
                  <input type="text" className="other" placeholder='Autre' />
                  <p><button className="btn btn-primary send" onClick={() => handleSendResponse(data.id)}>Envoyer</button></p>
                </div>
              }
             </div>
           </div>
            )
          }

     {
            reporting.length != 0 && reporting.map((data, item) => 
            data.type === "patient_index_low" &&
           <div className="card-survey">
             <div className="title-wrapper">
               <div className="title-evaluation">
                 <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
                 <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
               </div>
             </div>
             <div className="question">
               <p className="text-wrapper-14">{data.question}</p>
               <div className="evaluation">
               {showButton === true && <button className="btn btn-primary yes" onClick={() => handleButtonLowIndexButton(data.id, 'oui')}>Oui</button> }
                {showButton === true &&  <button className="btn btn-primary no" onClick={() => handleButtonLowIndexButton(data.id, 'non')}>Non</button> }
               </div>
               {showQuestion === true && 
                <div className="question-no">
                  <ul>
                  <li className={`${selectedGoal == 1 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(1, e)}>{selectedGoal != 1? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je n'ai pas eu le temps </li>
                  <li className={`${selectedGoal == 2 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(2, e)}>{selectedGoal != 2 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je n'ai pas réussi à joindre le centre </li>
                  <li className={`${selectedGoal == 3 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(3, e)}>{selectedGoal != 3 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Le centre ne me propose pas de crénaux disponibles</li>
                  </ul>
                  <input type="text" className="other" placeholder='Autre' />
                  <p><button className="btn btn-primary send" onClick={() => handleSendResponse(data.id)}>Envoyer</button></p>
                </div>
              }
             </div>
           </div>
            )
          }

        {
          reporting.length != 0 && reporting.map((data, item) => 
         data.type === "missed_appointment"  && 
           <div className="card-survey">
           <div className="title-wrapper">
             <div className="title-evaluation">
               <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
               <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
             </div>
           </div>
           <div className="question">
             <p className="text-wrapper-14">{data.question}</p>
             {showSeanceMissed === true && 
             <div className="evaluation">
             <ul className="session-missing">
                <li className={`${selectedGoal == 1 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(1, e)}>{selectedGoal != 1? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } C'est une erreur j'y suis allé </li>
                <li className={`${selectedGoal == 2 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(2, e)}>{selectedGoal != 2 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } J'ai eu un empêchement mais j'irai à la prochaine séance </li>
                <li className={`${selectedGoal == 3 && 'active'}`} key='1' onClick={(e) => handleChooseC(3, e)}>{selectedGoal != 3 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je ne suis plus motivé(e) et j'ai envie d'abandonner </li>
                </ul>
                <p><button className="btn btn-primary send" onClick={() => handleSendResponse(data.id)}>Envoyer</button></p>
             </div>
            }
             {showQuestionC === true && 
              <div className="question-no">
                 <p className="help">Avez-vous besoin d'aide pour continuer votre programme d'activité physique adaptée ?</p>
                <p>
                  <button className="btn btn-primary yes" onClick={() => handleYesRatingButton(data.id)}>Oui</button>
                  <button className="btn btn-primary no" onClick={handleNoSessionMissingRatingButton}>Non</button>
                </p>
              </div>
            }
           </div>
         </div>
      )
    }
        <div className="frame-3 scheduled">
          {sessionDate.map((date, item) => 
          <div className="session-list">
          <InformationDate
            className="information-date-instance"
            img="/img/line-4.svg"
            line="/img/line-3.svg"
            lineClassName="information-date-3"
            text=""
          />
          <CardRdv className="card-RDV-instance" vector="/img/vector-1.svg"  date={formatDate(date)} hour={formatHour(date)} activities={activities} name={centerName} address={centerAddress} postal={centerPostal}/>
          </div>
          )}
          <InformationDate
            className="information-date-instance"
            img="/img/line-4.svg"
            line="/img/line-3.svg"
            lineClassName="information-date-3"
            text="ven. 05 mai 2023"
          />
         <CardAjoutDUne ellipse="/img/ellipse-35.png"  name={nameDoctor} address={addressDoctor} postal_code={postalCode} city={city}  dowloadPrescription={downloadPrescription}/>
        </div>
        <BubbleInfo
          buttonBubbleIconEssentialEmojiIconEssentialEmojiClassName="bubble-info-3"
          buttonBubbleIconEssentialShareWrapperIconEssentialShareClassName="bubble-info-5"
          buttonBubblePropertyDefaultClassName="bubble-info-2"
          buttonBubblePropertyDefaultClassNameOverride="bubble-info-4"
          className="bubble-info-instance"
          type="variant-2"
        />
        <HeaderMobile
          className="header-mobile-instance"
          iconTimeCalendarIconTimeCalendarClassName="header-mobile-2"
          logoPropertyDefault="/img/logo.svg"
          type="logo-calendar-profile"
        />
          </div>
      }

      { tabActive === "session-done" &&
          <div>
             {objectifsetted === false && 
            <div className="first-session">
              <p className="text-wrapper-5">Bravo ! Vous avez terminé votre première séance.</p>
              <p className="p">Vous retrouverez ici tout ce dont vous aurez besoin dans votre parcours Activité Physique Adaptée.</p>
            </div>
}
            {objectifsetted === true && <div className='confirm-goal'>
          <p className='heart-circle'><img src='/img/heart-circle-black.png' /></p>
          <h6>Votre objectif est :</h6>
          <h4 className='goal-confirmed'>{objectif}</h4>
          </div>
          }
        <div className="frame-3 evaluation">
          
          {isQRAvailable === true &&
          <>
          <InformationDate
              className="information-date-instance"
              img="/img/line-4.svg"
              line="/img/line-3.svg"
              lineClassName="information-date-3"
              text={formattedDate}
            />

            <CardRdv className="card-RDV-instance" vector="/img/vector-1.svg"  date={formatDate(today)} hour={formatHour(today)} activities={activities} name={centerName} address={centerAddress} postal={centerPostal}/>

            <div className='qr-code'>
              <p className="text">Validez votre présence à la séance du {formattedDate} avec ce code</p>
              <p className="image"><img src={imageUrl} /></p>
            </div>
            </>
          }
          {
            reporting.length != 0 && reporting.map((data, item) => 
            data.type !== "no_appointment_scheduled" && data.type !== "missed_appointment"  && data.type !== 'patient_index_low' &&
            <div className="card-survey">
             <div className="title-wrapper">
               <div className="title-evaluation">
                 <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
                 <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
               </div>
             </div>
             <div className="question">
               <p className="text-wrapper-14">{data.question}</p>
               <div className="evaluation">
                 <ButtonEvaluation className="button-evaluation" rating_id={data.id}/>
                 <Group5 className="group" rating_id={data.id}/>
                 <Group6 fill="#84D038"  className="group-6" rating_id={data.id}/>
                 <Group51 className="type-bien-state-active" rating_id={data.id}/>
               </div>
             </div>
           </div>
 
            )}
 
          {
            reporting.length != 0 && reporting.map((data, item) => 
            data.type === "no_appointment_scheduled" &&
           <div className="card-survey">
             <div className="title-wrapper">
               <div className="title-evaluation">
                 <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
                 <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
               </div>
             </div>
             <div className="question">
               <p className="text-wrapper-14">{data.question}</p>
               <div className="evaluation">
               {showButton === true && <button className="btn btn-primary yes" onClick={() => handleYesRatingButton(data.id)}>Oui</button> }
                {showButton === true &&  <button className="btn btn-primary no" onClick={handleNoRatingButton}>Non</button> }
               </div>
               {showQuestion === true && 
                <div className="question-no">
                  <ul>
                  <li className={`${selectedGoal == 1 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(1, e)}>{selectedGoal != 1? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je n'ai pas eu le temps </li>
                  <li className={`${selectedGoal == 2 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(2, e)}>{selectedGoal != 2 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je n'ai pas réussi à joindre le centre </li>
                  <li className={`${selectedGoal == 3 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(3, e)}>{selectedGoal != 3 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Le centre ne me propose pas de crénaux disponibles</li>
                  </ul>
                  <input type="text" className="other" placeholder='Autre' />
                  <p><button className="btn btn-primary send" onClick={() => handleSendResponse(data.id)}>Envoyer</button></p>
                </div>
              }
             </div>
           </div>
            )
          }

     {
            reporting.length != 0 && reporting.map((data, item) => 
            data.type === "patient_index_low" &&
           <div className="card-survey">
             <div className="title-wrapper">
               <div className="title-evaluation">
                 <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
                 <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
               </div>
             </div>
             <div className="question">
               <p className="text-wrapper-14">{data.question}</p>
               <div className="evaluation">
               {showButton === true && <button className="btn btn-primary yes" onClick={() => handleButtonLowIndexButton(data.id, 'oui')}>Oui</button> }
                {showButton === true &&  <button className="btn btn-primary no" onClick={() => handleButtonLowIndexButton(data.id, 'non')}>Non</button> }
               </div>
               {showQuestion === true && 
                <div className="question-no">
                  <ul>
                  <li className={`${selectedGoal == 1 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(1, e)}>{selectedGoal != 1? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je n'ai pas eu le temps </li>
                  <li className={`${selectedGoal == 2 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(2, e)}>{selectedGoal != 2 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je n'ai pas réussi à joindre le centre </li>
                  <li className={`${selectedGoal == 3 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(3, e)}>{selectedGoal != 3 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Le centre ne me propose pas de crénaux disponibles</li>
                  </ul>
                  <input type="text" className="other" placeholder='Autre' />
                  <p><button className="btn btn-primary send" onClick={() => handleSendResponse(data.id)}>Envoyer</button></p>
                </div>
              }
             </div>
           </div>
            )
          }
          
          {
          reporting.length != 0 && reporting.map((data, item) => 
         data.type === "missed_appointment"  && 
           <div className="card-survey">
           <div className="title-wrapper">
             <div className="title-evaluation">
               <div className="text-wrapper-13"><img src='/img/heart-circle.png' /> Évaluation quotidienne</div>
               <div className="evaluation-todo"><img src='/img/state pill.png' /> A faire</div>
             </div>
           </div>
           <div className="question">
             <p className="text-wrapper-14">{data.question}</p>
             {showSeanceMissed === true && 
             <div className="evaluation">
             <ul className="session-missing">
                <li className={`${selectedGoal == 1 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(1, e)}>{selectedGoal != 1? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } C'est une erreur j'y suis allé </li>
                <li className={`${selectedGoal == 2 && 'active'}`} key='1' onClick={(e) => handleChooseGoal(2, e)}>{selectedGoal != 2 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } J'ai eu un empêchement mais j'irai à la prochaine séance </li>
                <li className={`${selectedGoal == 3 && 'active'}`} key='1' onClick={(e) => handleChooseC(3, e)}>{selectedGoal != 3 ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } Je ne suis plus motivé(e) et j'ai envie d'abandonner </li>
                </ul>
                <p><button className="btn btn-primary send" onClick={() => handleSendResponse(data.id)}>Envoyer</button></p>
             </div>
            }
             {showQuestionC === true && 
              <div className="question-no">
                 <p className="help">Avez-vous besoin d'aide pour continuer votre programme d'activité physique adaptée ?</p>
                <p>
                  <button className="btn btn-primary yes" onClick={() => handleYesRatingButton(data.id)}>Oui</button>
                  <button className="btn btn-primary no" onClick={handleNoSessionMissingRatingButton}>Non</button>
                </p>
              </div>
            }
           </div>
         </div>
      )
    }

          {sessionDate.map((date, item) => 
          <div className="session-list">
          <InformationDate
            className="information-date-instance"
            img="/img/line-4.svg"
            line="/img/line-3.svg"
            lineClassName="information-date-3"
            text="ven. 05 mai 2023"
          />
          <CardRdv className="card-RDV-instance" vector="/img/vector-1.svg" date={formatDate(date)} hour={formatHour(date)} activities={activities} name={centerName} address={centerAddress} postal={centerPostal}/>
          </div>
          )}
          <CardAjoutDUne ellipse="/img/ellipse-35.png"  name={nameDoctor} address={addressDoctor} postal_code={postalCode} city={city}  dowloadPrescription={downloadPrescription}/>
        </div>
        <BubbleInfo
          buttonBubbleIconEssentialEmojiIconEssentialEmojiClassName="bubble-info-3"
          buttonBubbleIconEssentialShareWrapperIconEssentialShareClassName="bubble-info-5"
          buttonBubblePropertyDefaultClassName="bubble-info-2"
          buttonBubblePropertyDefaultClassNameOverride="bubble-info-4"
          className="bubble-info-instance"
          type="variant-2"
        />
        <HeaderMobile
          className="header-mobile-instance"
          iconTimeCalendarIconTimeCalendarClassName="header-mobile-2"
          logoPropertyDefault="/img/logo.svg"
          type="logo-calendar-profile"
        />
          </div>
      }

{ tabActive === "goal" &&
          <div>
            <div className="first-session">
              <p className="text-wrapper-5">Première évaluation terminée</p>
              <p className="p">Pour vous motiver, vous pouvez désormais vous donner un objectif</p>
              <button className="btn btn-primary"> Définir mon objectif </button>
            </div>
        <div className="frame-3">
          <InformationDate
            className="information-date-instance"
            img="/img/line-4.svg"
            line="/img/line-3.svg"
            lineClassName="information-date-3"
            text="ven. 05 mai 2023"
          />
          <CardRdv className="card-RDV-instance" vector="/img/vector-1.svg" activities={activities} name={centerName} address={centerAddress} postal={centerPostal}/>
          <CardAjoutDUne ellipse="/img/ellipse-35.png"  name={nameDoctor} address={addressDoctor} postal_code={postalCode} city={city}  dowloadPrescription={downloadPrescription}/>
        </div>
        <BubbleInfo
          buttonBubbleIconEssentialEmojiIconEssentialEmojiClassName="bubble-info-3"
          buttonBubbleIconEssentialShareWrapperIconEssentialShareClassName="bubble-info-5"
          buttonBubblePropertyDefaultClassName="bubble-info-2"
          buttonBubblePropertyDefaultClassNameOverride="bubble-info-4"
          className="bubble-info-instance"
          type="variant-2"
        />
        <HeaderMobile
          className="header-mobile-instance"
          iconTimeCalendarIconTimeCalendarClassName="header-mobile-2"
          logoPropertyDefault="/img/logo.svg"
          type="logo-calendar-profile"
        />
          </div>
      }
      
      <ModalCentered
        show={showModal}
        onHide={() => onClose()}
        title= ''
        subtitle= 'Merci pour votre réponse'
        message='Votre réponse a bien été prise en compte.'
      />
      
    </div>
  );
};

TabsTabs.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  line: PropTypes.string,
  tabsTabItemLine: PropTypes.string,
};
