import React, { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import PasswordGenerator from '../../components/Patients/PasswordGenerator/PasswordGenerator'
import './style.css';
import { Logo } from '../../components/Patients/Logo/Logo';
import { api_url } from '../../globarvariable';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {

    const [password, setPassword] = useState('');
    const [childOfChildValue, setChildOfChildValue] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const searchParams = new URLSearchParams(window.location.search);
    const key = searchParams.get('key');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleChildOfChildValueChange = (value) => {
        setChildOfChildValue(value);
        setPassword(value);
    };

    const handleClick = () => {

        setLoading(true);
        const formData = {
            password: password
        }

        fetch(api_url+'api/users/me', {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${key}`,
            },
            body: JSON.stringify(formData),
          }).then((response) => response.json())
          .then((data) => {
              if(data.id){
                setError(false);
                setSuccess(true);
                setMessage('Votre mot de passe a été réinitialisé avec succès');
                setLoading(false);
                if(data.role === 'Patient') {
                    navigate('/login')
                }
                else if(data.role === 'Doctor'){
                    navigate('/')
                }
                else if(data.role === 'Eapa'){
                    navigate('/login-eapa')
                }
                  
              }
          })
          .catch(() => {
            setSuccess(false);
            setLoading(false);
                setError(true);
                setMessage('Une erreur est survenue lors de l\'opération');
          })     
    }
    
    
  return (
    <div>
        <section className="vh-100 bg-image reset" id="sign-up">
        <div className="mask d-flex align-items-center h-100 gradient-custom-3">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div
                className="col-12 col-md-9 col-lg-7 col-xl-5"
                id="card-first-div"
              >
                <div className="card">
                  <div className="card-body p-5">
                  <Logo
          className="class logo-auth resetPasswordLogo"
          property1="default"
          propertyDefault= "/img/logo-1.svg"
        />
                    <p className="text-uppercase text-center">
                     <strong> Vous avez oublié votre mot de passe ? </strong>
                    </p>
                    <p className='reinitialise'>Vous pouvez réinitialiser votre mot de passe ici</p>
                    
                    <PasswordGenerator handleValueChange={handleChildOfChildValueChange}/>

                    {success === true && <p className='success'>{message}</p>}
                    {error === true && <p className='error'>{message}</p> }
                    {loading && <p className='loading'><ClipLoader color="#123abc" loading={loading} /></p>}
                    <button
                        type="button"
                        className="btn btn-primary btn-block mb-4"
                        onClick={handleClick}
                      >
                        Réinitialiser
                    </button>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ResetPassword