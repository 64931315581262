import React, { useCallback, useEffect, useRef, useState } from 'react'
import './style.css'
import { useSelector } from 'react-redux';
import { api_url } from '../../../globarvariable';

const FinalPrescription = () => {
  
  const prescriptionId = useSelector((action : any) => action.exerciseIdReducer);
  const accessToken = localStorage.getItem('accessToken');
  const prescription_id = localStorage.getItem('prescriptionId');
  const [infoPrescription, setInfoPrescription] = useState();
  const [content, setContent] = useState<any>(null);
  const [edit, setEdit] = useState('Modifier');
  const [editable, setEditable] = useState(false);

  const contentEditableRef = useRef<HTMLDivElement | null>(null);

  const exerciseIds : any[] = [];

  const onClickEdit = () => {
    if (contentEditableRef.current) {
      contentEditableRef.current.focus();
    }
    setEdit('Enregistrer');
    setEditable(true);
    document.getElementById('prescription')?.focus();

    if(edit === 'Enregistrer' && contentEditableRef.current){
      contentEditableRef.current.style.border = 'initial';
      setEdit('Modifier');
      var editor_innerhtml = document.getElementById('editor')?.innerHTML;
      const formData = {
        custom_content: editor_innerhtml,
      };

      fetch(api_url+'api/prescriptions/'+prescription_id, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      })

      setEditable(false);

    }

  }


  useEffect(() => {
    if (editable && contentEditableRef.current) {
      contentEditableRef.current.focus();
      contentEditableRef.current.style.border = '2px solid black';
    }
  }, [editable]);

  const onChangeEditor = () => {

    var editor_innerhtml = document.getElementById('editor')?.innerHTML;
    setContent(editor_innerhtml)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(api_url+'api/prescriptions/'+prescription_id, {
          headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${accessToken}`,
          },
        });
        const jsonData = await response.json();
        setInfoPrescription(jsonData);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      }
    }


    fetchData();

  }, []);

  useEffect(() => {
    /*var content_first = document.getElementById('content')?.innerHTML;
    setContent(content_first);*/

  }, [infoPrescription]);

  return (
    <>
    <h1 className='title'>Votre prescription</h1>
    <p className='edit-save' onClick={onClickEdit}>{edit}</p>
    <div id="editor"  contentEditable={editable} suppressContentEditableWarning={true}>
    <div className='prescription' id="prescription" ref={contentEditableRef}>
<p>Je soussigné(e) {infoPrescription && infoPrescription.doctor.first_name} {infoPrescription && infoPrescription.doctor.last_name}, Docteur en Médecine, certifie avoir examiné le ou la patiente {infoPrescription && infoPrescription.patient.first_name}  {infoPrescription && infoPrescription.patient.last_name} et n’avoir constaté, à ce jour, aucune contre-indication apparente à la pratique de l’activité physique

Je prescris une activité physique adaptée supervisée pendant {infoPrescription && infoPrescription.exercises[0].activity_duration} semaines, à une fréquence de  {infoPrescription && infoPrescription.week_frequency} séances par semaine, à une intensité à adapter en fonction de l’évolution des aptitudes du patient, qui l’amènent progressivement vers celle recommandée en thérapeutique non médicamenteuse, en privilégiant le développement des capacités suivantes :</p>

{ infoPrescription && infoPrescription.exercises.map((exercise, index) => (
    <div>
        <p><strong> Exercice  {index + 1}</strong></p>

        <p><strong> Activité</strong> : {exercise.activity.name} pendant {exercise.activity_duration} semaine(s)</p>

        <p> <strong> {exercise.define_by_eapa === true && "Je laisse l’enseignant d’activité physique adaptée définir et adapter le type exercice avec le patient"}</strong></p>


        { exercise.define_by_eapa === true && exercise.types.length > 0 && <p><strong>A titre indicatif</strong></p>}
        
        {exercise.types.length > 0 && 
        <div>
        <p><strong>Type d’exercice</strong> :{ exercise.types.map((exerciseType, index) => ( <React.Fragment key={index}> {exerciseType.type.name} {index < exercise.types.length - 1 && ","} </React.Fragment> )) }</p>

       

        <p>{exercise.type_description}</p>

        <p><strong>Intensité</strong> : {exercise.intensity.name}</p>

        <p>{exercise.intensity_description}</p>

        {exercise.serie_number && <p><strong>Nombre de séries </strong> : {exercise.serie_number} fois pendant {exercise.serie_duration} min </p> }
    
        <p>Cette pratique devra être supervisée par un intervenant qualifié ayant les compétences requises conformément à l’instruction ministérielle, pour encadrer des patients présentant les limitations fonctionnelles suivantes :</p>

          { exercise.precautions.map((precaution, index) => {

              exerciseIds.push(exercise.id);

              return (
            <p>{precaution.name} </p>
            )
          }) }
      </div>
    }
         
    </div>   
))}

<p>PRÉCONISATIONS PARTICULIÈRES ET COMPLÉMENTS D’INFORMATION À L’INTENTION DE L’INTERVENANT PERMETTANT D’ADAPTER ET DE SÉCURISER LA PRATIQUE (exemples non exhaustifs : activités avec le poids du corps ou charges légères, pas de sport à risque de chute et/ou de collision, éviter efforts en ambiance froide, éviter efforts statiques intenses, contrôler l’intensité pour ne pas dépasser une intensité modérée, traitements à prendre en compte...)</p>
</div>
    </div>
  </>
  )
}

export default FinalPrescription