/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  iconLeft2: boolean;
  iconRight: boolean;
  iconLeft: boolean;
  size: "google-agenda" | "outlook" | "whatsapp" | "tertiary" | "messenger" | "secondary" | "primary";
  state: "disabled" | "initial";
  color: "dark" | "transparent" | "light";
  className: any;
  text: string;
  onClick: (e) => void;
}

export const ButtonAction = ({
  iconLeft2 = true,
  iconRight,
  iconLeft,
  size,
  state,
  color,
  className,
  text = "Label",
  onClick
}: Props): JSX.Element => {
  return (
    <div className={`button-action ${size} color-${color} state-1-${state} ${className}`}>
      {["google-agenda", "messenger", "outlook", "whatsapp"].includes(size) && (
        <>
          <>{iconLeft2 && <div className="icon-company-logos" />}</>
        </>
      )}

      <div className="connecter-whatsapp" onClick={onClick}>
        {size === "whatsapp" && <>Connecter Whatsapp</>}

        {size === "google-agenda" && <>Connecter Google Agenda</>}

        {size === "messenger" && <>Connecter Messenger</>}

        {size === "outlook" && <>Connecter Outlook</>}

        {["primary", "secondary", "tertiary"].includes(size) && <>{text}</>}
        <img src='/img/arrow-right.png' />
      </div>
    </div>
  );
};

ButtonAction.propTypes = {
  iconLeft2: PropTypes.bool,
  iconRight: PropTypes.bool,
  iconLeft: PropTypes.bool,
  size: PropTypes.oneOf(["google-agenda", "outlook", "whatsapp", "tertiary", "messenger", "secondary", "primary"]),
  state: PropTypes.oneOf(["disabled", "initial"]),
  color: PropTypes.oneOf(["dark", "transparent", "light"]),
  text: PropTypes.string,
};
