import userIdReducer from "./userId";
import loggedReducer from "./isLogged.";
import centerIdReducer from "./CenterId";
import { combineReducers } from "redux";
import { all } from "axios";
import exerciseIdReducer from "./ExerciseId";


const allReducers = combineReducers({
    userIdReducer: userIdReducer,
    loggedReducer: loggedReducer,
    centerIdReducer: centerIdReducer,
    exerciseIdReducer: exerciseIdReducer
})

export default allReducers;