import React, { useEffect, useState } from 'react'
import { Logo } from '../../components/Patients/Logo'
import './style.css'
import { api_url } from '../../globarvariable';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../../components/Medecins/SearchBar/SearchBar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Pagination from '../../components/Medecins/Pagination/Pagination';

export const SearchPatient = (): JSX.Element =>  {

    const [patients, setPatients] = useState<any[]>([]);
    const [data, setData] = useState(patients);
    const accessToken = localStorage.getItem('accessToken');
    const [doctorName, setDoctorName] = useState('');
    const navigate = useNavigate();
    const title = 'Recherchez un patient';
    const [total, setTotal] = useState<number>();
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const formatDate = (dateString) => {
      // Create a Date object from the input string
      const date = new Date(dateString);
  
      // Extract the components of the date
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');
  
      // Format the date part as 'YYYY-MM-DD'
      const formattedDate = `${day}-${month}-${year}`;
  
      return formattedDate;
    };



    const downloadPDF = async (idPrescription, first_name, last_name) => {
      const response = await fetch(api_url+'api/prescriptions/'+idPrescription+'/download', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      }).then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        const date = new Date();
        link.setAttribute('download', first_name+'_'+last_name+'_'+formatDate(date)+'.pdf');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading PDF:', error);
      });
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(api_url + 'api/patients?page='+currentPage+'&limit='+perPage, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
  
          // Destructure the response data
          const { totals, per_page, patients: responseData } = response.data;
          let updatedataTable;
  
          // Update state with the fetched data
          setTotal(response.data.pages.total);
          setPerPage(response.data.pages.per_page);
          setPatients(response.data.patients);
          //setData(response.data.patients);
  
          if(response.data.pages.total){
             updatedataTable = await Promise.all(responseData.map(async (patient, index) => {
              return {
                name: (
                  <>
                    <p className="fw-bold mb-1">{patient !== null && `${patient.first_name} ${patient.last_name}`}</p>
                    <p className="text-muted mb-0">{patient !== null && patient.email}</p>
                  </>
                ),
                center: (
                  <>
                    <p className="fw-normal mb-1">{patient.center !== null && patient.center.name}</p>
                    <p className="text-muted mb-0">{patient.center !== null && patient.center.address}, {patient.center !== null && patient.center.city}</p>
                  </>
                ),
                date: (
                  <>
                    <p className="fw-normal mb-1">{patient.latest_prescription !== null && formatDate(patient.latest_prescription.created_at)}</p>
                  </>
                ),
                suivi: (
                  <>
                    {patient.followed === true && <span className="badge-followed">Suivi</span>}
                    {patient.followed === false && <span className="badge-non-followed">Non Suivi</span>}
                  </>
                ),
                prescription: (
                  <>
                    <button type="button" className="btn btn-primary new-prescription" data-mdb-ripple-color="dark" onClick={() => downloadPDF(patient.latest_prescription.id, patient.first_name, patient.last_name)}>Télécharger la prescription <img src="/img/document.png" /></button>
                  </>
                )
              };
            }));
          }
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [currentPage]); 

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(api_url + 'api/patients', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
  
          // Destructure the response data
          const { totals, per_page, patients: responseData } = response.data;
          let updatedataTable;
  
          setData(response.data.patients);
          setCurrentPage(1); 
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []); 

    useEffect(() => {
      const  extractInitials = (name) => {
        // Split the name into separate words
        const words = name.split(' ');
        
        // Extract the first letter of each word
        const initials = words.map(word => word.charAt(0));
        
        // Concatenate the initials
        return initials.join('');
      }

      if(localStorage.getItem('doctor')){
        setDoctorName(extractInitials(localStorage.getItem('doctor')));
      }
        
    }, []);




      const handleClickNewPrescription = () => {
        localStorage.removeItem('center_id');
        navigate('/prescription-medecin');
      }

      const logout = () => {
        confirmAlert({
          title: 'Confirmation',
          message: 'Êtes-vous sûr de vouloir vous déconnecter ?',
          buttons: [
            {
              label: 'Non',
              className: 'logout-no',
              onClick: () => window.location.reload()
            },
            {
              label: 'Oui',
              className: 'logout-yes',
              onClick: () => {
                localStorage.removeItem('accessToken');
                navigate('/');
              }
            }

          ]
        })
    }

    const handlePagination = (pageNumber) => {
      setCurrentPage(pageNumber); 
    };

    /*const indexOfLastPatient = currentPage * perPage;
    const indexOfFirstPatient =  indexOfLastPatient - perPage;*/
    const currentpatient = patients.slice(0, perPage);

  return (
    <div>
 <header className="p-3 mb-3">
    <div className="container recherche-patient">
      <div className="row">
        <div className='col-md-3'>
        <a href="/medecin" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
        <Logo
          className="class logo-auth"
          property1="default"
          propertyDefault= "/img/logo-1.svg"
        /></a>
        </div>
        <div className='col-md-9'>
        <div className="dropdown text-end">
        <button type="button" className="btn btn-primary new-prescription" data-mdb-ripple-color="dark" onClick={handleClickNewPrescription}>Nouvelle prescription <img src="/img/add-circle.png" /></button>
         <img src='/img/messages.svg' className='messageIcon' /> 
         <a href="/searchAPA"><img src='/img/search-status.svg' className='message-searchAPA'/></a>
          <a href="#" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
            {doctorName}
          </a>
          
          <img src="/img/logout.svg" alt="mdo" width="32" height="32" className="rounded-circle logout"  onClick={logout}/>
        </div>
        </div>
      </div>
    </div>
  </header>   
  <div className="container">    

<SearchBar list={data} setList={setPatients} filterField={(item) => item.first_name} address={false} city={false} Title={title} patient={true}/>
<table className="table align-middle mb-0 bg-white">
  <thead className="bg-light">
    <tr>
      <th>PATIENT</th>
      <th>CENTRE APA</th>
      <th>DATE DE PRESCRIPTION</th>
      <th>SUIVI/NON SUIVI</th>
      <th>PRESCRIPTION</th>
    </tr>
  </thead>
  <tbody>
    { 
    
    currentpatient.map((patient) => (
        <tr key={patient.id}>
      <td>
        <div className="d-flex align-items-center">
          <div className="ms-3">
            <p className="fw-bold mb-1">{patient !== null && patient.first_name} {patient !== null && patient.last_name}</p>
            <p className="text-muted mb-0">{patient !== null && patient.email}</p>
          </div>
        </div>
      </td>
      <td>
        <p className="fw-normal mb-1">{patient.center !== null && patient.center.name}</p>
        <p className="text-muted mb-0">{patient.center !== null &&  patient.center.address}, {patient.center !== null &&  patient.center.city}</p>
      </td>
      <td>
      <div className="d-flex align-items-center">
      <div className="ms-3">
      <p className="fw-normal mb-1">{patient.latest_prescription !== null && formatDate(patient.latest_prescription.created_at)}</p>
      </div>
      </div>
      </td>
      
      <td>
        {patient.followed === true && <span className="badge-followed">Suivi</span>}
        {patient.followed === false && <span className="badge-non-followed">Non Suivi</span>}
      </td>
      <td>
      <button type="button" className="btn btn-primary new-prescription" data-mdb-ripple-color="dark" onClick={() => downloadPDF(patient.latest_prescription.id, patient.first_name, patient.last_name)}>Télécharger la prescription <img src="/img/document.png" /></button>
      </td>
    </tr> 
    ))}
    {currentpatient.length == 0 && 
    <tr>
      <td>
        <p>Vous n'avez pas encore de patient</p>
      </td>
    </tr>
    }
    
  </tbody>
</table>
</div>

<Pagination
  length={total}
  postsPerPage={perPage}
  currentPage={currentPage}
  handlePagination={handlePagination}
/>
    </div>
  );
};