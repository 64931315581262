import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  state: "read" | "unread";
  className: any;
}

export const ChatMessage = ({ state, className }: Props): JSX.Element => {
  return (
    <div className={`chat-message ${className}`}>
      <p className="lorem-ipsum-dolor">Lorem ipsum dolor sit amet consectetur. Faucibus scelerisque.</p>
      <div className="element">09:41</div>
      <div className="name">
        {state === "unread" && <div className="ellipse" />}

        <div className="paul-gramard">Paul Gramard</div>
      </div>
    </div>
  );
};

ChatMessage.propTypes = {
  state: PropTypes.oneOf(["read", "unread"]),
};
