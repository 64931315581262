import React, { useEffect, useState } from 'react';
import './style.css';

interface Props {
    list: any;
    setList: any;
    filterField: any;
    address: any;
    city: any;
    patient: any;
    Title: any;

}
const SearchBar = ({list, setList, filterField = item => item, address, city, patient, Title} : Props) => {
  
    const [value, setValue] = useState('');

    const filteredList = () => {
            return list.filter(item => {
                
                const first_name = item.first_name.toLowerCase().includes(value.toLocaleLowerCase())
                const last_name = item.last_name.toLowerCase().includes(value.toLocaleLowerCase())

                if (value.toLowerCase() === `${item.first_name.toLowerCase()} ${item.last_name.toLowerCase()}` || value.toLowerCase() === `${item.last_name.toLowerCase()} ${item.first_name.toLowerCase()}`) {
                    return true;
                }
            
            
                return first_name || last_name;
            })
    }

    useEffect(() => {
        if(value){
            setList(filteredList);
        }
        else{
            setList(list);
        }
    }, [value])
  
    const handleChange = (e) => {
        setValue(e.target.value)
    }

  return (
<div className='search-patient'>
    <h2>{Title}</h2>
    { patient && <input type="text" id="form12" className="form-control" placeholder="Entrez le nom d'un patient" value={value} onChange={handleChange}/> }
    
  </div>
  )
}

export default SearchBar