import React from 'react'
import { Admin, ListGuesser, Resource, useAuthenticated} from 'react-admin'
import jsonServerProvider from 'ra-data-json-server';
import UserList from '../../components/Admin/DataProvider/UserList';
import simpleRestProvider from 'ra-data-simple-rest'
import { api_url } from '../../globarvariable';
import { Auth0AuthProvider } from 'ra-auth-auth0';

const authProvider = {
  login: params => Promise.resolve(),
  logout: params => Promise.resolve(),
  checkAuth: params => Promise.resolve(),
  checkError: error => Promise.resolve(),
  getIdentity: params => Promise.resolve(),
  getPermissions: params => Promise.resolve(),
};

const AdminDashboard = () => {
  const dataProvider = simpleRestProvider(api_url+'api');
  return (
    <div>
      <Admin dataProvider={dataProvider} authProvider={authProvider} requireAuth>
        <Resource name="patient" list={UserList} />
    </Admin></div>
  )
}

export default AdminDashboard