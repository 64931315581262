/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useState } from "react";
import { ButtonAction } from "../ButtonAction";
import { Tag } from "../Tag";
import "./style.css";
import { useNavigate } from "react-router-dom";

interface Props {
  tags: boolean;
  property1: "variant-2" | "default";
  className: any;
  line: string;
  key:any
  name: string
  address: string
  postal_code: string
  city: string
  carte: boolean
  prescription: boolean
  distance: any
  activities: []
  showContinue: boolean
  onClick: (id) => void;
  onClickButton: (id) => void;
}

export const Centre = ({ tags = true, property1, className, line = "/img/line-10-5.svg", key,name, address, postal_code, city, carte= true, prescription=false, distance,activities, showContinue, onClick, onClickButton }: Props): JSX.Element => {
  
  const navigate = useNavigate();

  const getGoogleMapsLink = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps/place/${encodedAddress}`;
  };

  const handlePeSubmit = (e) => {
    navigate('/medecin/prescription');
  };

  return (
    <div>
    <div className={`container centre ${className} `} onClick={onClick} key={key}>
          <div className="row">
              <div className="col-md-8">
                    <h3 className="subtitle-centre">{name}</h3>
                    <p>{address}, {postal_code} {city} <img className="line" alt="Line" src={line} /> {distance} Km</p>
                    <div className="activity-name">
                        {activities.map((activity, index) => (
                            <div className="btn btn-light">{activity.name}</div>
                      ))}
        
                    </div>
              </div>
              <div className="col-md-4">
              {prescription === true &&<button type="button" className="btn btn-outline-primary first" data-mdb-ripple-color="dark" onClick={onClickButton}>Continuer avec ce centre </button>}
              {showContinue === true && <button type="button" className="btn btn-primary button-centre" data-mdb-ripple-color="dark"  onClick={handlePeSubmit}>Valider et continuer <img src='/img/arrow-right.png' /></button>}
              </div>
          </div>
    </div>
    </div>
  );
};

Centre.propTypes = {
  tags: PropTypes.bool,
  property1: PropTypes.oneOf(["variant-2", "default"]),
  line: PropTypes.string,
};
