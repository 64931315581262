import React, { useState } from "react";
import { BubbleInfo } from "../../components/Patients/BubbleInfo";
import { CardAjoutDUne } from "../../components/Patients/CardAjoutDUne";
import { CardProgrammerUne } from "../../components/Patients/CardProgrammerUne";
import { HeaderMobile } from "../../components/Patients/HeaderMobile";
import { IconArrowArrow } from "../../components/Patients/IconArrowArrow";
import { InformationDate } from "../../components/Patients/InformationDate";
import { TabsTabs } from "../../components/Patients/TabsTabs";
import "./style.css";
import { ChatMessage } from "../../components/Patients/ChatMessage/ChatMessage";

export const ObservanceMobile = (): JSX.Element => {

  const [tab, setTab] = useState('observance');

  return (
    <div className="observance-mobile">
      <div className="div-2">
        <TabsTabs
          className="tabs-tabs-instance"
          line="/img/line-1.svg"
          lineClassName="tabs-tabs-2"
          property1="default"
          tabsTabItemFrameClassName="tabs-tabs-3"
          tabsTabItemFrameClassNameOverride="tabs-tabs-6"
          tabsTabItemLine="/img/line-2-1.svg"
          tabsTabItemLineClassName="tabs-tabs-5"
          tabsTabItemLineClassNameOverride="tabs-tabs-8"
          tabsTabItemStateActiveClassName="tabs-tabs-4"
          tabsTabItemStateActiveClassNameOverride="tabs-tabs-7"
        />

      </div>
    </div>
  );
};
