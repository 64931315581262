/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ButtonAction } from "../ButtonAction";
import "./style.css";

interface Props {
  ellipse: string;
  name: any;
  address: any;
  postal_code: any;
  city: any;
  dowloadPrescription: any
}

export const CardAjoutDUne = ({ ellipse = "/img/ellipse-35-1.png", name, address, postal_code, city, dowloadPrescription }: Props): JSX.Element => {
  return (
    <div className="card-ajout-d-une">
      <p className="votre-prescription-a">Votre prescription a été ajoutée par :</p>
      <div className="div">
        <img className="ellipse" alt="Ellipse" src={ellipse} />
        <p className="dr-jean-michel">
          <span className="text-wrapper-2">
            Dr {name}
            <br />
          </span>
          <span className="text-wrapper-3">
            {address}
            <br />
            {postal_code} {city}
          </span>
        </p>
      </div>
      <ButtonAction
        className="design-component-instance-node my-prescription"
        color="transparent"
        size="tertiary"
        state="initial"
        text="Télécharger ma prescription"
        onClick={dowloadPrescription}
      />
    </div>
  );
};

CardAjoutDUne.propTypes = {
  ellipse: PropTypes.string,
};
