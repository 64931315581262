import React, { useEffect, useState } from "react";
import { ButtonAction } from "../../components/Patients/ButtonAction";
import { HeaderMobile } from "../../components/Patients/HeaderMobile";
import { HomeIndicator } from "../../components/Patients/HomeIndicator";
import { TextFieldBasic } from "../../components/Patients/TextFieldBasic";
import "./style.css";
import PasswordGenerator from "../../components/Patients/PasswordGenerator/PasswordGenerator";
import axios from "axios";
import { api_url } from "../../globarvariable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

export const InscriptionPatientMobile = (): JSX.Element => {

  const[stateButton, setStateButton] = useState('disabled');
  const[lastName, setLastName] = useState('');
  const[firstName, setFirstName] = useState('');
  const[email, setEmail] = useState('');
  const[error, setError] = useState(false);
  const[errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [childOfChildValue, setChildOfChildValue] = useState('');
  const userId = useSelector((action : any) => action.userIdReducer);
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cgu, setCgu]= useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChildOfChildValueChange = (value) => {
    setChildOfChildValue(value);
    setPassword(value);
  };

  const handleSubmit =  async () => {
    setLoading(true);
    const formData = {
      last_name: lastName,
      first_name: firstName,
      email: email,
      password: password,
    };

    fetch(api_url+'api/users/'+userId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.id){
          setTimeout(() => {
            setLoading(false);
          }, 2000);
          navigate('/patient');
        }
        else{
            setError(true);
            setErrorMessage(data.detail)
        }
      })
      .catch((error) => {
        console.error('Error inserting data:', error);
      });

  }

  useEffect(() => {

    if(lastName != '' && firstName != '' && email != '' && password != '' && cgu === true){
      setIsDisabled(false);
    }

  }, [lastName,firstName,email,password, cgu]);

  useEffect(() => {

    const fetchData = () => {
      const response =  axios.get(api_url+'api/users/'+userId, {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) =>{
        setLastName(response.data.last_name);
        setFirstName(response.data.first_name);
        setEmail(response.data.email);
      })
      //
    }


    fetchData();

  }, []);

  const handleCGU = () => {
    setCgu(true);
    if(cgu === true){
      setCgu(false);
    }
  }
  
  return (
    <div className="inscription-mobile">
      <div className="div-3">
        <p className="p">Pour commencer, nous allons créer votre compte.</p>
        <div className="text-wrapper-2">Vos informations personnelles</div>
        <div className="text-wrapper-3">Votre mot de passe</div>
        <TextFieldBasic
          background="transparent"
          className="text-field-basic-instance"
          state="initial"
          text={lastName ? lastName :"Votre nom"}
          type="classic"
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextFieldBasic
          background="transparent"
          className="design-component-instance-node"
          state="initial"
          text={firstName ? firstName : "Votre prénom"}
          type="classic"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextFieldBasic
          background="transparent"
          className="text-field-basic-2"
          state="initial"
          text={email ? email : "Votre adresse email"}
          type="classic"
          onChange={(e) => setEmail(e.target.value)}
        />
        <PasswordGenerator handleValueChange={handleChildOfChildValueChange}/>

        <p className="cgu patient">
        <input type='checkbox' onChange={handleCGU} /> En soumettant ce formulaire, je consens explicitement à ce que les informations saisies soient sauvegardées et exploitées dans le cadre de mon suivi d’activité physique sur la plateforme APAORA
        </p>
        <button
          className="button-action-instance sign-up btn btn-primary"
          color="dark"
          onClick={handleSubmit}
          type="button"
          disabled={isDisabled}> Continuer </button>
        {loading && <ClipLoader className='loader' color="#123abc" loading={loading} />}
        <div className="keyboard">
          <div className="overlap">
            <HomeIndicator className="home-indicator-instance" mode="light" />
          </div>
        </div>
        <HeaderMobile className="header-mobile-instance" logoPropertyDefault="/img/logo-1.svg" type="logo" />
      </div>
    </div>
  );
};
