import React from 'react'
import { Datagrid, EmailField, List, TextField } from 'react-admin'

const UserList = () => {
  return (
    <div>
            <List>
        <Datagrid rowClick="edit">
            <TextField source="action" />
            <TextField source="user.first_name" />
            <TextField source="user.last_name" />
            <EmailField source="user.email" />
            <TextField source="user.address" />
            <TextField source="user.doctor.first_name" />
            <TextField source="user.center.name" />
        </Datagrid>
    </List>
    </div>
  )
}

export default UserList