/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconArrowArrow } from "../IconArrowArrow";
import "./style.css";

interface Props {
  state: "filled" | "active" | "initial";
  background: "white" | "transparent";
  type: "dropdown" | "text-area" | "classic" | "email" | "tel" | "code-postal";
  className: any;
  text: string;
  iconArrowArrowIconArrowArrowClassName: any;
  id: string;
  onBlur: (e) => void;
}

export const TextFieldBasic = ({
  state,
  background,
  type,
  className,
  text = "Label",
  iconArrowArrowIconArrowArrowClassName,
  id,
  onBlur
}: Props): JSX.Element => {
  return (
    <div className={`text-field-basic ${type} ${state} ${background} ${className}`}>
      {["classic", "text-area"].includes(type) && <input
        type="text"
        id={id}
        className="form-control form-control-lg"
        name={text}
        placeholder={text}
        onChange={onBlur}
      />}

      {["email"].includes(type) && <input
        type="email"
        id={id}
        className="form-control form-control-lg"
        name="email"
        placeholder={text}
        onChange={onBlur}
      />}

      {["code-postal"].includes(type) && <input
        type="text"
        id={id}
        className="form-control form-control-lg"
        name="code-postal"
        placeholder={text}
        maxLength={5}
        onChange={onBlur}
      />}

      {["tel"].includes(type) && <input
        type="tel"
        id={id}
        className="form-control form-control-lg phone"
        name="phone"
        placeholder={text}
        onChange={onBlur}
      />}

      {state === "filled" && ["classic", "text-area"].includes(type) && <input
        type="text"
        id={id}
        className="form-control form-control-lg"
        name={text}
        placeholder={text}
        onChange={onBlur}
      />}

      {state === "active" && background === "white" && type === "dropdown" && (
        <>
          <div className="option">Option 1</div>
          <div className="option-2">Option 2</div>
          <div className="option-3">Option 3</div>
          <img className="line" alt="Line" src="/img/line-11-1.svg" />
          <img className="img" alt="Line" src="/img/line-12-1.svg" />
          <img className="line-2" alt="Line" src="/img/line-13-1.svg" />
        </>
      )}

      {type === "dropdown" && (
        <>
          <IconArrowArrow className={`${state === "active" ? "class" : iconArrowArrowIconArrowArrowClassName}`} />
          <div className="label-2">{text}</div>
        </>
      )}

      {state === "active" && background === "transparent" && type === "dropdown" && (
        <>
          <div className="option">Option 1</div>
          <div className="option-2">Option 2</div>
          <div className="option-3">Option 3</div>
          <img className="line" alt="Line" src="/img/line-11-1.svg" />
          <img className="img" alt="Line" src="/img/line-12-1.svg" />
          <img className="line-2" alt="Line" src="/img/line-13-1.svg" />
        </>
      )}
    </div>
  );
};

TextFieldBasic.propTypes = {
  state: PropTypes.oneOf(["filled", "active", "initial"]),
  background: PropTypes.oneOf(["white", "transparent"]),
  type: PropTypes.oneOf(["dropdown", "text-area", "classic", "email", "tel", "code-postal"]),
  text: PropTypes.string,
};
