/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  unit: boolean;
  className: any;
  lunMaiClassName: any;
  line: string;
  lineClassName: any;
  img: string;
  text: string;
}

export const InformationDate = ({
  unit,
  className,
  lunMaiClassName,
  line = "/img/line-3-1.svg",
  lineClassName,
  img = "/img/line-4-1.svg",
  text
}: Props): JSX.Element => {
  return (
    <div className={`information-date ${className}`}>
      <div className={`lun-mai ${lunMaiClassName}`}>{text}</div>
      <img className="line-3" alt="Line" src={line} />
      <img className={`line-4 ${lineClassName}`} alt="Line" src={img} />
    </div>
  );
};

InformationDate.propTypes = {
  unit: PropTypes.bool,
  line: PropTypes.string,
  img: PropTypes.string,
};
