/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useState } from "react";
import { api_url } from "../../globarvariable";
import ModalCentered from "../../components/Patients/Modal/Modal";

interface Props {
  className: any;
  fill: any;
  rating_id: any;
}

export const Group6 = ({fill = "#84D038", className, rating_id }: Props): JSX.Element => {
  
  const accessToken = localStorage.getItem('accessToken');
  const [showModal, setShowModal] = useState(false);

  const handleClickReporting = (rate) => {
    const formData = {
      rate: rate,
    };

    fetch(api_url+'api/ratings/'+rating_id+'/rate', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json())
    .then((data) => {
        if(data.id){
            setShowModal(true)
        }
    })
  }

  const onClose = () => {
    setShowModal(false)
    window.location.reload();
  }
  
  return (
    <svg
      className={`group-6 ${className}`}
      fill="none"
      height="70"
      viewBox="0 0 70 70"
      width="70"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => handleClickReporting('Bien')}
    >
      <rect className="rect" fill="#F9F9F9" height="69.6947" rx="16" width="69.6947" />
      <path
        className="path"
        d="M47.6 9.0022C42.3608 9.0271 37.5431 11.9121 35 16.5468C31.8342 10.7204 25.1699 7.80427 18.8062 9.46021C12.442 11.1161 7.99517 16.9239 8 23.573C8 39.2146 26.5256 60 35 60C43.4744 60 62 39.2146 62 23.573C61.9908 15.5294 55.5488 9.01154 47.6 9.0022ZM35 58.1786C28.25 58.1786 9.8 38.5596 9.8 23.573C9.79649 17.4242 14.1326 12.15 20.1083 11.0343C26.0839 9.9191 31.9946 13.2808 34.1593 19.0259C34.2925 19.3785 34.6269 19.611 35 19.611C35.3731 19.611 35.7075 19.3785 35.8407 19.0259C38.0054 13.2808 43.9161 9.9191 49.8917 11.0343C55.8674 12.15 60.2035 17.4242 60.2 23.573C60.2 38.5596 41.75 58.1786 35 58.1786Z"
        fill={fill}
      />
      <path
        className="path"
        d="M27 28C27 29.6567 25.8806 31 24.5 31C23.1194 31 22 29.6567 22 28C22 26.3428 23.1194 25 24.5 25C25.8806 25 27 26.3428 27 28Z"
        fill={fill}
      />
      <path
        className="path"
        d="M47 28C47 29.6567 45.8806 31 44.5 31C43.1194 31 42 29.6567 42 28C42 26.3428 43.1194 25 44.5 25C45.8806 25 47 26.3428 47 28Z"
        fill={fill}
      />
      <path
        className="path"
        d="M41.0625 36C40.5448 36 40.125 36.4473 40.125 37C40.125 39.7568 37.6023 42 34.5 42C31.3977 42 28.875 39.7568 28.875 37C28.875 36.4473 28.4552 36 27.9375 36C27.4198 36 27 36.4473 27 37C27 40.8599 30.3646 44 34.5 44C38.6354 44 42 40.8599 42 37C42 36.4473 41.5802 36 41.0625 36Z"
        fill={fill}
      />
            
                    <ModalCentered
        show={showModal}
        onHide={() => onClose()}
        title= 'Félicitations !'
        subtitle= 'Evaluation terminée'
        message='Votre évaluation a bien été prise en compte. Merci !'
      />
    </svg>
  );
};
