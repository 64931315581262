import React, { useEffect, useState } from "react";
import validator from "validator";
import "./style.css";

interface Props {
  handleValueChange: any;
}


const PasswordGenerator = ({handleValueChange}: Props) => {
  const [length, setLength] = useState(12);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState('');
  const [manualPassword, setmanualPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [useUppercase, setUseUppercase] = useState(true);
  const [useLowercase, setUseLowercase] = useState(true);
  const [useNumbers, setUseNumbers] = useState(true);
  const [useSymbols, setUseSymbols] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [statusPassword, setStatusPassword] = useState("");
  const [copied, setCopied] = useState(true);

  function generatePassword() {
    const length = 12;
    const useUppercase = true;
    const useLowercase = true;
    const useNumbers = true;
    const useSymbols = true;

    let charset = "";
    if (useUppercase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    if (useLowercase) charset += "abcdefghijklmnopqrstuvwxyz";
    if (useNumbers) charset += "0123456789";
    if (useSymbols) charset += "!@#$%^&*";

    let password = "";
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    setPassword(password);
    setConfirmPassword(password);
  }

  // Handling the password change
  const handlePassword = (e: any) => {
    setPassword(e.target.value);
    if (
      validator.isStrongPassword(e.target.value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 0,
        minNumbers: 1,
        minSymbols: 0,
      })
    ) {
      setMessage("Mot de passe long et sécurisé");
      setStatus("OK");
    } else {
      setMessage("Veuillez saisir 08 caractères minimum");
      setStatus("KO");
    }
  };

  
  const handleConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
    if (password === confirmPassword) {
      setStatusPassword("OK");
      setSuccess('Les mots de passe correspondent.');
      handleValueChange(confirmPassword);
    } else {
      setStatusPassword("KO");
      setError('Les mots de passe ne correspondent pas.');
    }
  };


  const handleChecked = (e: any) => {
    if (e.target.checked) {
      setCopied(false);
    } else {
      setCopied(true);
    }
  };

  function modal() {
    setShowModal(true);
  }

  function confirm() {
    setShowModal(false);
  }

  function cancelModal() {
    setShowModal(false);
  }

  function copyPassword() {
    if (password) {
      navigator.clipboard.writeText(password);
    }
  }

  return (
    <div className="password-generator">
      <div className="form-outline mb-4">
        <input
          type="password"
          id="password"
          className="form-control form-control-lg password"
          name="password"
          placeholder="Choisissez un mot de passe"
          value={password}
          onChange={handlePassword}
          autoComplete="off"
        />
      </div>

      {status === "OK" ? (
        <span
          style={{
            fontWeight: "bold",
            color: "green",
          }}
        >
          {message}
        </span>
      ) : (
        <span
          style={{
            fontWeight: "bold",
            color: "red",
          }}
        >
          {message}
        </span>
      )}

      <div className="form-outline mb-4">
        <input
          type="password"
          id="passwordConfirmation"
          className="form-control form-control-lg passwordConfirmation"
          name="passwordConfirmation"
          placeholder="Confirmer votre mot de passe"
          value={confirmPassword}
          onInput={(e:any) => setConfirmPassword(e.target.value)}
          onBlur={handleConfirmPassword}
        />
      </div>

      { statusPassword === "OK" && <p className="success">{success}</p>}
      { statusPassword === "KO" && <p className="error">{error}</p>}

    </div>
  );
};

export default PasswordGenerator;
