/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconEssentialEmoji } from "../IconEssentialEmoji";
import { IconEssentialShareWrapper } from "../IconEssentialShareWrapper";
import "./style.css";

interface Props {
  property1: "variant-2" | "variant-3" | "default";
  className: any;
  iconEssentialEmojiIconEssentialEmojiClassName: any;
  iconEssentialShareWrapperIconEssentialShareClassName: any;
  onClick: () => void;
  iconEssentialCloseClassName: any;
}

export const ButtonBubble = ({
  property1,
  className,
  iconEssentialEmojiIconEssentialEmojiClassName,
  iconEssentialShareWrapperIconEssentialShareClassName,
  onClick,
  iconEssentialCloseClassName,
}: Props): JSX.Element => {
  return (
    <div className={`button-bubble property-1-${property1} ${className}`} onClick={onClick}>
      {property1 === "default" && <div className={`icon-essential-close ${iconEssentialCloseClassName}`} />}

      {property1 === "variant-2" && <IconEssentialEmoji className={iconEssentialEmojiIconEssentialEmojiClassName} />}

      {property1 === "variant-3" && (
        <IconEssentialShareWrapper className={iconEssentialShareWrapperIconEssentialShareClassName} />
      )}
    </div>
  );
};

ButtonBubble.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "variant-3", "default"]),
};
