import React from "react";
import ReactDOMClient from "react-dom/client";
import { ObservanceMobile } from "./screens/ObservanceMobile";
import Code from "./components/Patients/Code/Code";
import { OnboardingMobile } from "./screens/OnboardingMobile/OnboardingMobile";
import { InscriptionPatientMobile } from "./screens/InscriptionPatientMobile";
import 'bootstrap/dist/css/bootstrap.css'
import { DonnesPatient } from "./screens/DonnesPatient/DonnesPatient";
import { BrowserRouter, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AppRoutes from "./Routes";
import App from "./App";
import {createStore} from 'redux';
import allReducers from "./reducers";
import { Provider } from "react-redux";

//root.render(<ObservanceMobile />);
//root.render(<OnboardingMobile />);
//root.render(<InscriptionPatientMobile />);
//root.render(<DonnesPatient />);
//root.render(<Code />);


//Store -> Globalized state
const store = createStore(allReducers)


ReactDOMClient.createRoot(document.getElementById('app')).render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
        
    </React.StrictMode>
)

