const initialState = null;
const ExerciseIdReducer = (state = initialState, action) => {
    switch(action.type){
        case 'exerciseId' : 
            return action.payload
        default:    
            return state
    }
    }

export default ExerciseIdReducer;
    