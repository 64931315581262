/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useState } from "react";
import { api_url } from "../../globarvariable";
import ModalCentered from "../../components/Patients/Modal/Modal";

interface Props {
  className: any;
  rating_id: any;
}

export const Group51 = ({className, rating_id }: Props): JSX.Element => {

  const accessToken = localStorage.getItem('accessToken');
  const [showModal, setShowModal] = useState(false);

  const handleClickReporting = (rate) => {
    const formData = {
      rate: rate,
    };

    fetch(api_url+'api/ratings/'+rating_id+'/rate', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json())
    .then((data) => {
        if(data.id){
            setShowModal(true)
        }
    })
  }

  const onClose = () => {
    setShowModal(false)
    window.location.reload();
  }
  
  
  return (
    <svg
      className={`type-bien-state-active ${className}`}
      fill="none"
      height="70"
      viewBox="0 0 70 70"
      width="70"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => handleClickReporting('Tres bien')}
    > 
<rect x="0.000488281" y="-0.0175781" width="69.6947" height="69.6947" rx="16" fill="#F9F9F9"/>
<path d="M47.6005 9.98458C42.3613 10.009 37.5436 12.8374 35.0005 17.3813C31.8347 11.6691 25.1704 8.81014 18.8066 10.4336C12.4425 12.0571 7.99566 17.751 8.00049 24.2697C8.00049 39.6045 26.5261 59.9824 35.0005 59.9824C43.4749 59.9824 62.0005 39.6045 62.0005 24.2697C61.9913 16.3838 55.5493 9.99374 47.6005 9.98458ZM35.0005 58.1968C28.2505 58.1968 9.80049 38.9624 9.80049 24.2697C9.79698 18.2414 14.1331 13.0706 20.1087 11.9769C26.0844 10.8835 31.9951 14.1793 34.1598 19.8117C34.293 20.1574 34.6274 20.3854 35.0005 20.3854C35.3736 20.3854 35.708 20.1574 35.8412 19.8117C38.0059 14.1793 43.9166 10.8835 49.8922 11.9769C55.8679 13.0706 60.204 18.2414 60.2005 24.2697C60.2005 38.9624 41.7505 58.1968 35.0005 58.1968Z" fill="#00AEA4"/>
<path d="M22.8005 30.1491C22.8005 28.7681 24.0094 27.6491 25.5005 27.6491C26.9916 27.6491 28.2005 28.7681 28.2005 30.1491C28.2005 30.6093 28.6035 30.9824 29.1005 30.9824C29.5975 30.9824 30.0005 30.6093 30.0005 30.1491C30.0005 27.8477 27.9856 25.9824 25.5005 25.9824C23.0154 25.9824 21.0005 27.8477 21.0005 30.1491C21.0005 30.6093 21.4035 30.9824 21.9005 30.9824C22.3975 30.9824 22.8005 30.6093 22.8005 30.1491Z" fill="#00AEA4"/>
<path d="M44.5005 25.9824C42.0163 25.9849 40.0036 27.8489 40.0005 30.1491C40.0005 30.6093 40.4035 30.9824 40.9005 30.9824C41.3975 30.9824 41.8005 30.6093 41.8005 30.1491C41.8005 28.7681 43.0094 27.6491 44.5005 27.6491C45.9916 27.6491 47.2005 28.7681 47.2005 30.1491C47.2005 30.6093 47.6035 30.9824 48.1005 30.9824C48.5975 30.9824 49.0005 30.6093 49.0005 30.1491C48.9974 27.8489 46.9847 25.9849 44.5005 25.9824Z" fill="#00AEA4"/>
<path d="M47.1338 36.9824H22.8672C22.3885 36.9824 22.0005 37.3658 22.0005 37.8396C22.0005 43.9835 27.8323 48.9824 35.0005 48.9824C42.1687 48.9824 48.0005 43.9835 48.0005 37.8396C48.0005 37.3658 47.6124 36.9824 47.1338 36.9824ZM35.0005 47.2681C29.1323 47.2681 24.2988 43.4964 23.7808 38.6967H46.2202C45.7022 43.4964 40.8687 47.2681 35.0005 47.2681Z" fill="#00AEA4"/>

      <ModalCentered
        show={showModal}
        onHide={() => onClose()}
        title= 'Félicitations !'
        subtitle= 'Evaluation terminée'
        message='Votre évaluation a bien été prise en compte. Merci !'
      />
    </svg>
  );
};
