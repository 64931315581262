/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useState } from "react";
import { ButtonAction } from "../ButtonAction";
import "./style.css";

interface Props {

  name: any;
  address: any;
  postal: any
}

export const CardProgrammerUne = ({name, address, postal}: Props): JSX.Element => {
  const[phoneNumber, setPhoneNumber] = useState('tel:+33626883944');
  return (
    <div className="card-programmer-une">
      <p className="programmez-vos-s">Programmez vos séances avec votre centre APA :</p>
      <p className="maison-hygeia">
      <span className="text-wrapper">
          {name}
          <br />
        </span>
        <span className="span">
         {address}<br />
         {postal}
        </span>
      </p>
      <a href={phoneNumber}>
      <ButtonAction
        className="button-action-instance"
        color="dark"
        size="primary"
        state="initial"
        text="Prendre rendez-vous"
      />
      </a>
    </div>
  );
};
