/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react'
import { IconTimeCalendar } from "../IconTimeCalendar";
import { Logo } from "../Logo";
import { ProfilePicture } from "../ProfilePicture";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

interface Props {
  notificationCalendar: boolean;
  type: "logo-calendar-profile" | "title-arrow" | "logo";
  className: any;
  iconTimeCalendarIconTimeCalendarClassName: any;
  logoPropertyDefault: string;
}

export const HeaderMobile = ({
  notificationCalendar,
  type,
  className,
  iconTimeCalendarIconTimeCalendarClassName,
  logoPropertyDefault = "/img/logo-2.svg",
}: Props): JSX.Element => {

  const [eapaName, setEapaName] = useState('');
  const navigate = useNavigate();
  
  const logout = () => {
    confirmAlert({
      title: 'Confirmation',
      
      message: 'Êtes-vous sûr de vouloir vous déconnecter ?',
      buttons: [
        {
          label: 'Non',
          className: 'logout-no',
          onClick: () => window.location.reload()
        },
        {
          label: 'Oui',
          className: 'logout-yes',
          onClick: () => {
            localStorage.removeItem('accessToken');
            navigate('/login', { replace: true });
          }
        }
      ]
    })
}

const handleNavigate = () => {
  const pathName = window.location.pathname;
  if(pathName === "/patient"){
    navigate('/calendar');
  }
  else if(pathName === "/eapa"){
    navigate('/calendarEAPA');
  }
}

useEffect(() => {
  const  extractInitials = (name) => {
    // Split the name into separate words
    const words = name.split(' ');
    
    // Extract the first letter of each word
    const initials = words.map(word => word.charAt(0));
    
    // Concatenate the initials
    return initials.join('');
  }

  if(localStorage.getItem('name')){
    setEapaName(extractInitials(localStorage.getItem('name')));
  }
    
}, []);

  return (
    <div className={`header-mobile ${className}`}>
      {type === "logo-calendar-profile" && <IconTimeCalendar className={iconTimeCalendarIconTimeCalendarClassName} onClick={handleNavigate}/>}

      {["logo-calendar-profile", "logo"].includes(type) && (
        <Logo
          className={`${type === "logo" ? "class" : "class-2"}`}
          property1="default"
          propertyDefault={type === "logo" ? "/img/logo-1.svg" : logoPropertyDefault}
        />
        
      )}

      {type === "logo-calendar-profile" && (
        <ProfilePicture className="profile-picture-instance" size="small" state="empty" name={eapaName} />
      )}

      {type === "title-arrow" && (
        <>
          <div className="icon-arrow-arrow-2" />
          <div className="calendrier">Calendrier</div>
        </>
      )}
      <img src="/img/logout.svg" alt="mdo" width="32" height="32" className="rounded-circle logout"  onClick={logout}/>
    </div>
  );
};

HeaderMobile.propTypes = {
  notificationCalendar: PropTypes.bool,
  type: PropTypes.oneOf(["logo-calendar-profile", "title-arrow", "logo"]),
  logoPropertyDefault: PropTypes.string,
};
