/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useState } from "react";
import "./style.css";

interface Props {
  property1: "filled" | "active" | "initial";
  className: any;
  rank: any;
  onChange: (event) => void;
  onBlur: (event) => void;
}

export const TextFieldNumber = ({ property1, className, rank, onChange, onBlur }: Props): JSX.Element => {
  const [activated, setActivated] = useState(true); 

  return (
    <div className={`text-field-number property-1-${property1} ${className}`}>
      {["filled", "initial"].includes(property1) && <input className="form-control" type="text" name={`code-${rank}`}  aria-label="default input example" maxLength={1} onChange={onChange} onBlur={onBlur}/>}
    </div>
  );
};

TextFieldNumber.propTypes = {
  property1: PropTypes.oneOf(["filled", "active", "initial"]),
};
