import React, { useEffect, useState } from "react";
import { BottomButtons } from "../../components/Medecins/BottomButtons";
import { Centre } from "../../components/Medecins/Centre";
import { Steps } from "../../components/Medecins/Steps";
import "./style.css";
import { UseSelector, useSelector } from 'react-redux/es/hooks/useSelector';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonAction } from "../../components/Medecins/ButtonAction";
import axios from "axios";
import { api_url } from "../../globarvariable";
import { getCenterId } from "../../actions/centerId";
import { Logo } from "../../components/Patients/Logo";
import Geocode from 'react-geocode';
import { getDistance } from "geolib";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon } from "leaflet";
import { string } from "prop-types";

export const DonnesCentre = (): JSX.Element => {

  const[selected, setSelected] = useState(null);
  const [ButtonState, setButtonState] = useState(true);
  const [centersData, setCentersData] = useState<any[]>([]);
  const [userAddress, setUserAddress] = useState('');
  const [distancesArray, setDistancesArray] = useState(Array<string>);
  const [mapCenter, setMapCenter] = useState<any>([48.863014, 2.346702]); // Initial center
  const [mapZoom, setMapZoom] = useState(12); 
  const [latitudeGivenAddress, setLatitudeGivenAddress] = useState(0);
  const [longitudeGivenAddress, setLongitudeGivenAddress] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showContinue , setShowContinue] = useState(false);
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePeSubmit = (e) => {
    navigate('/medecin/prescription');
  };

  const userId = useSelector((action : any) => action.userIdReducer);
  const userIdLocal = localStorage.getItem('user_id');
  Geocode.setApiKey('AIzaSyAFMZ7sAnfmjTgceZ2h4pfmkQNAmdo_F54');

  function FlyMapTo() {

    const map = useMap()

    useEffect(() => {
        map.flyTo(mapCenter, mapZoom)
    }, [mapCenter])

    return null
}


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(api_url + 'api/users/' + userIdLocal, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
    
        setUserAddress(response.data.address);
        localStorage.setItem('address', response.data.address);
    
        // Use a try-catch block for error handling when using async/await
        try {
          const resp = await Geocode.fromAddress(response.data.address);
          const { lat, lng } = resp.results[0].geometry.location;
          setLatitudeGivenAddress(lat);
          setLongitudeGivenAddress(lng);
        } catch (error) {
          console.error('Error fetching geocode data:', error);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };    
    fetchData();
  }, [])

  const handleClickCentre = (key) => {

    setShowContinue(true);
    if(selected === null){
      setSelected( key === selected ? null : key);
      setButtonState(false);
    }
    else{
      setSelected( key === selected ? 0 : key);
      setButtonState(false);
    }

    const formData = {
      center_id: key,
    };

    fetch(api_url+'api/users/'+userIdLocal, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    })



    dispatch(getCenterId(key));
    localStorage.setItem('center_id', key);
      
  }

  useEffect(() => {
    const fetchData = async () => {
      class Center {
        id: number;
        name: string;
        address: string;
        postal_code: string;
        city: string;
        latitude: number;
        longitude: number;
        phone_number: string;
        activities: string;
      }

      let response;
      let distances: Array<string> = [];
      let centers: Array<Center> = [];

      // Fetch centers data from API
      try {
        response = await axios.get(api_url + 'api/centers', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
        }).then((response) => {
          centers = response.data.centers;
        });
      } catch (error) {
        response = null;
        console.error('Error fetching center from api:', error);
      }

      // Filter centers by distance with geocode
      if (centers) {
        try {
          const geocode = await Geocode.fromAddress(localStorage.getItem('address'));
          const { lat, lng } = geocode.results[0].geometry.location;
          
          centers = centers.filter((center) => {
              const latitude = center.latitude;
              const longitude = center.longitude;
              const distance = getDistance(
                { latitude: lat, longitude: lng },
                { latitude, longitude }
              );
              return distance;
          });

          centers = centers.sort((a, b) => {
            const distanceA = getDistance(
              { latitude: lat, longitude: lng },
              { latitude: a.latitude, longitude: a.longitude }
            );
            const distanceB = getDistance(
              { latitude: lat, longitude: lng },
              { latitude: b.latitude, longitude: b.longitude }
            );
            return distanceA - distanceB;
          });

          distances = centers.map((center) => {
            const latitude = center.latitude;
            const longitude = center.longitude;
            const distance = getDistance(
              { latitude: lat, longitude: lng },
              { latitude, longitude }
            );
            // Return true for items that meet your distance criteria
            const result = distance / 1000;
            return result.toFixed(1);
          });
        } catch (error) {
            console.error('Error fetching geocode data:', error);
        }

        setDistancesArray(distances);
        setCentersData(centers);
      };
    }
  
    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      // Define your scroll condition here
      if(scrollTop > 200){
        setIsScrolled(true);
      } 
      else{
        setIsScrolled(false);
      }
      
    };

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 
  
  const handleMarkerClick = (markerId) => {
    // Handle the marker click action here
    setSelected(markerId);
  };

  return (
    <>
    <header className="p-3 mb-3 prescription-header">
    <a href="/medecin" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
        <Logo
          className="class logo-auth"
          property1="default"
          propertyDefault= "/img/logo-1.svg"
        /></a>
  </header> 
    <div className="donnes-centre">
      <div className="div-2">
      <div className="row">
      <div className="col-md-3">
      <Steps
          className={`steps-instance ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="design-component-instance-node"
          iconEssentialTickIconEssentialTickClassName="steps-5"
          state="done"
          text="PATIENT"
          type="patient"
        />
        <Steps
          className={`steps-6 ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="design-component-instance-node"
          iconEssentialTickIconEssentialTickClassName="steps-7"
          renseignezLesClassName="steps-2"
          state="in-progress"
          text1="CENTRE APA"
          text2="Choisissez le centre"
          type="centre-APA"
        />
        <Steps
          className={`steps-8 ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="design-component-instance-node"
          iconEssentialTickIconEssentialTickClassName="steps-7"
          renseignezLesClassName="steps-2"
          state="to-do"
          type="prescription"
        />
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-8 choice-center">
      <h1 className='title-center'>Choisissez votre centre APA pour votre patient</h1>
      <p className='proximity'>{centersData.length} CENTRES APA À PROXIMITÉ</p>
      {
         centersData.map((center, index) =>(
          <Centre className={`${selected == center.id && 'active'}`} line="/img/line-10-2.svg" property1="default" key={center.id} name={center.name}  address={center.address} postal_code={center.postal_code} city={center.city} distance={distancesArray[index]}  activities={center.activities} showContinue={selected == center.id && showContinue} onClick={() => handleClickCentre(center.id)}/>
         ))
      }
        
         <div className="row bottom-buttons centre-button-continue">
        <div className="col-md-12">
        </div>
        <MapContainer center={[48.863014, 2.346702]} zoom={12} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        {
         centersData.map((center, index) =>(
          <div>
          <Marker position={[center.latitude, center.longitude]} icon={new Icon({iconUrl: "/img/marker-icon.png", iconSize: [25, 41], iconAnchor: [12, 41]})}  eventHandlers={{
            click: () => handleMarkerClick(center.id),
          }}>
            <Popup>
              {center.name} <br /> {center.phone_number}
            </Popup>
          </Marker>
          </div>
         ))
        }
           <Marker position={[latitudeGivenAddress, longitudeGivenAddress]} icon={new Icon({iconUrl: "/img/marker-icon-red.png", iconSize: [25, 41], iconAnchor: [12, 41]})} >
            <Popup>
              {localStorage.getItem('address')}
            </Popup>
          </Marker>
        </MapContainer>
      </div>
      </div>
      </div>
      </div>
    </div>
    </>
  );
};
