/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Tag } from "../Tag";
import "./style.css";

interface Props {
  className: any;
  vector: string;
  date: any;
  hour: any;
  activities: any[];
  name:any;
  address: any;
  postal: any;
}

export const CardRdv = ({ className, vector = "/img/vector-1-1.svg", date, hour, activities, name, address, postal }: Props): JSX.Element => {
  return (
    <div className={`card-RDV ${className}`}>
      <div className="div">{date}</div>
      <div className="text-wrapper-2">Séance du</div>
      <div className="text-wrapper-3">Début</div>
      <div className="text-wrapper-4">{hour}</div>
      <img className="vector" alt="Vector" src={vector} />
      <div className="centre-cardRdv">
      <p className="maison-hygeia">
      <span className="text-wrapper">
          {name}
          <br />
        </span>
        <span className="span">
         {address}, {postal}
        </span>
      </p>
    </div>
    <div className="row activity-row">
      {activities.map((activity, index) =>
        <p className="col activity-tag"> {activity} </p>
      )}
    </div>
    </div>
  );
};

CardRdv.propTypes = {
  vector: PropTypes.string,
};
