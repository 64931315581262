import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import { api_url } from '../../globarvariable';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Goal = () => {

  const [selectedGoal, setSelectedGoal] = useState(null);
  const [textSelectedGoal, setTextSelectedGoal] = useState('');
  const [listGoal, setListGoal] = useState([]);
  const [goal1, setGoal1] = useState(0);
  const [goal2, setGoal2] = useState(0);
  const [goal3, setGoal3] = useState(0);
  const [goal, setGoal] = useState(0);
  const [step, setStep] = useState(1);
  const [description, setDescription] = useState('');
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  const handleChooseGoal = (key,e) => {
    if(selectedGoal === null){
      setSelectedGoal( key === selectedGoal ? null : key);
    }
    else{
      setSelectedGoal( key === selectedGoal ? 0 : key);
    }   
    
    if(key == 1){
      setGoal1(1);
      setGoal2(0);
      setGoal3(0);
    }
    if(key == 2){
      setGoal2(2);
      setGoal1(0);
      setGoal3(0);
    }
    if(key == 3){
      setGoal3(3);
      setGoal2(0);
      setGoal1(0);
    }
    setTextSelectedGoal(e.currentTarget.textContent);
  }

  const handleContinueGoal = () => {
      if(step === 1 ){
        setStep(2);
      }
      else if(step === 2){
        setStep(3);
      }

  }


  const handleLaterGoal = () => {
      setStep(2);
  }

  const handlePassGoal =  () => {
      setStep(3);
  }

  const handleNavigate = () => {
    if(step === 3 ){
      setStep(2);
    }
    else if(step === 2){
      setStep(1);
    }
    else if(step === 1){
      navigate('/patient');
    }

  }

  const handleConfirmGoal = () => {
    const formData = {
      description: description,
      goal_id: selectedGoal
    };

    fetch(api_url+'api/goals/my', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json())
    .then((data) => {
        if(data.id){
            navigate('/patient')
        }
    })
  }

  useEffect (() => {
    const fetchData = () => {
      const response =  axios.get(api_url+'api/goals', {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) =>{
         if(response.data){
              setListGoal(response.data.goals);
          }
      })
      //
    }


    fetchData();

  }, [])


  return (
    <div id='goal'>
        <header className='header-goal'>
        <img src='/img/arrow-left.png'  onClick={handleNavigate}/>
            <h5> Votre objectif</h5>
        </header>
        <div className='container'>
        {step == 1 && <div className='body'>
            <h5>Votre objectif est ce qui vous motive dans la poursuite de votre parcours Activité Physique Adaptée.</h5>
            <p className='detail'></p>
            <div className='list-goal'>
                    <ul>
                      {listGoal.map((list, index) =>(
                        <li className={`${selectedGoal == list.id && 'active'}`} key='1' onClick={(e) => handleChooseGoal(list.id, e)}>{selectedGoal != list.id ? <img src="/img/Radio.png" /> : <img src="/img/Radio-selected.png" /> } {list.name}</li>
                      ))
                      }
                        
                    </ul>
            </div>
            <p className='goal-button'><button className='btn btn-primary goal-confirm' onClick={handleContinueGoal}>Confirmer mon objectif </button></p>
            <p className='later'><a onClick={handleLaterGoal}>Je le ferai plus tard</a></p>
        
        </div>}

        {step == 2 && <div><h5 className='more'>Pouvez-vous nous en dire plus sur votre objectif ?</h5>
        <p className='details-goal-p'><textarea placeholder='Détaillez votre objectif' className='details-goal' value={description} onChange={(e) => setDescription(e.target.value)}></textarea></p>
        <p className='goal-button'><button className='btn btn-primary goal-confirm' onClick={handleContinueGoal}>Continuer</button></p>
        <p className='later'><a onClick={handlePassGoal}>Passer</a></p></div>}


        {step == 3 && <div className='confirm-goal'>
          <p className='heart-circle'><img src='/img/heart-circle-black.png' /></p>
          <h6>Votre objectif est :</h6>
          <h4 className='goal-confirmed'>{textSelectedGoal}</h4>
          <p className='detail'>{description != '' ? description : 'Vous n\'avez pas encore décrit votre objectif'}</p>
            <p className='goal-button'><button className='btn btn-primary goal-confirm' onClick={handleConfirmGoal}>Terminer</button></p>
          </div>
          
        }


        
        </div>
    </div>
  )
}

export default Goal