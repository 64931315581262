import React, { useEffect, useState } from 'react'
import './style.css'
import axios from 'axios';
import { api_url } from '../../../globarvariable';

interface Props {
  handleIdActivityChange: any;
  handleIdDurationChange: any;
  handleTypeIdChange: any;
  handleTypeDescriptionChange: any;
  handleIntensityIdChange: any;
  handleIntensityDescriptionChange: any;
  handleSerieNumberChange: any;
  handleSerieDurationChange: any;
  exercise: any;
  handlePrecaution1Change: any;
  handlePrecaution2Change: any;
  handlePrecaution3Change: any;
  handlePrecaution4Change: any;
  handlePrecautionAutreChange: any;
  handleCheckboxChange : any;

}

const Prescription = ({handleIdActivityChange, handleIdDurationChange,handleTypeIdChange, handleTypeDescriptionChange, handleIntensityIdChange, handleIntensityDescriptionChange, handleSerieNumberChange,handleSerieDurationChange, exercise, handlePrecaution1Change, handlePrecaution2Change, handlePrecaution3Change, handlePrecaution4Change, handlePrecautionAutreChange, handleCheckboxChange} : Props) => {

  const [selectedExercise, setSelectedExercise] = useState<any[]>([]);
  const [selectedIntensity, setSelectedIntensity] = useState(null);
  const [precaution, setPrecaution ] = useState(0);
  const [weekFrequency, setWeekFrequency] = useState('');
  const [idActivity, setIdActivity] = useState('');
  const [activityDuration, setActivityDuration] = useState(12);
  const [typeId, setTypeId] = useState(null);
  const [typeDescription, setTypeDescription] = useState('');
  const [intensityId, setIntensityId] = useState(null);
  const [intensityDescription, setIntensityDescription] = useState('');
  const [serieNumber, setSerieNumber] = useState('');
  const [serieDuration, setSerieDuration] = useState('');
  const [precaution1, setPrecaution1] = useState('');
  const [precaution2, setPrecaution2] = useState('');
  const [precaution3, setPrecaution3] = useState('');
  const [precaution4, setPrecaution4] = useState('');
  const [activityCenter, setActivityCenter] = useState([]);
  const accessToken = localStorage.getItem('accessToken');
  const center_id = localStorage.getItem('center_id');


  const handleIdActivity = (e) => {
    setIdActivity(e.target.value);
    handleIdActivityChange(e.target.value);
  }

  const handleActivityDuration = (e) => {
    const newValue = e.target.value;
    setActivityDuration(newValue !== '' ? newValue : 12);
  }

useEffect(() => {

  handleIdDurationChange(activityDuration);

}, [activityDuration])

  const handleChooseExercise = (key) => {

      setSelectedExercise(prevSelectedExercise  => {
        // Check if the key is already in the array
        const index = prevSelectedExercise .findIndex(item => item.type_id === key);

        if (index !== -1) {
          // If the key is already in the array, remove it
          return prevSelectedExercise .filter(item => item.type_id !== key);

        } else {
          // If the key is not in the array, add it
          return [...prevSelectedExercise , {
            "type_id": key}
           ];
        }
      }); 
      
    setTypeId(key);

  }

  useEffect(() => {
    handleTypeIdChange(selectedExercise);
  }, [selectedExercise])

  const handleTypeDescription = (e) => {
    setTypeDescription(e.target.value);
    handleTypeDescriptionChange(e.target.value);
  }



  const handleChooseIntensity = (key) => {
    if(selectedIntensity === null){
      setSelectedIntensity( key === selectedIntensity ? null : key);
      
    }
    else{
      setSelectedIntensity( key === selectedIntensity ? 0 : key);
    }
      
    setIntensityId(key);
    handleIntensityIdChange(key);
  }

  const handleIntensityDescription = (e) => {
    setIntensityDescription(e.target.value);
    handleIntensityDescriptionChange(e.target.value);
  }


  const handleSerieNumber = (e) => {
    setSerieNumber(e.target.value);
    handleSerieNumberChange(e.target.value);

  }

  const handleSerieDuration = (e) => {
    setSerieDuration(e.target.value);
    handleSerieDurationChange(e.target.value);

  }


  const handlePrecaution = () => {
    if(precaution == 0){
      setPrecaution(1);
    }
    else{
      setPrecaution(0);
    }
    
  }

  const handlePrecaution1 = (e) => {
    handlePrecaution1Change(e.target.value);
  }

  const handlePrecaution2 = (e) => {
    handlePrecaution2Change(e.target.value);
  }

  const handlePrecaution3 = (e) => {
    handlePrecaution3Change(e.target.value);
  }

  const handlePrecaution4 = (e) => {
    handlePrecaution4Change(e.target.value);
  }

  const handlePrecautionAutre = (e) => {
    handlePrecautionAutreChange(e.target.value)
  }

const handleCheckbox = (e) => {
    handleCheckboxChange(e.target.checked)
}


  useEffect(() => {
    const fetchData = () => {
      const response =  axios.get(api_url+'api/centers/'+center_id ,{
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      }).then((response) =>{
        setActivityCenter(response.data.activities);
      })
      //
    }

    fetchData();

  }, [])

  return (
    <div className='formulaire-prescription'>
          <h2 className="subtitle">{exercise}</h2>
          <div className="row exercise-details">
              <div className="col-md-6">
              <span>Détails de l'exercice</span>
              <select className="form-select activity" aria-label="Default select example" onChange={handleIdActivity}>
                  <option value='None' selected>Nom de l'activité</option>
                  {activityCenter.map((activity, index) => (
                    <option value={activity.activity_id}>{activity.name}</option>
                  ))}
  
              </select>
              </div>
              <div className="col-md-6">
              <span>Durée</span>
              <div className="form-outline">
              <input type="number" id="typeNumber" className="form-control week" value={activityDuration} onChange={handleActivityDuration} min={0}/>
                <label>semaines</label>
                </div>
              </div>
          </div>
          <div className='check-exercise-details'>
            <input type='checkbox' className='checkbox-' onChange={handleCheckbox}/>
            <strong>Je laisse l’enseignant d’activité physique adaptée définir et adapter le type exercice avec le patient</strong>
        </div>
          <span>Type d’exercice</span>
          <div className="row exercise-type">
              <button type="button" className={`btn btn-outline-primary first  ${selectedExercise.some(item => item.type_id === 1) && 'active'}`} data-mdb-ripple-color="dark" key='1' value='1' onClick={() => handleChooseExercise(1)}>Endurance</button>
              <button type="button" className={`btn btn-outline-primary second ${selectedExercise.some(item => item.type_id === 2) && 'active'}`} data-mdb-ripple-color="dark" key='2' value='2' onClick={() => handleChooseExercise(2)}>Equilibre</button>
              <button type="button" className={`btn btn-outline-primary third ${selectedExercise.some(item => item.type_id === 3) && 'active'}`} data-mdb-ripple-color="dark" key='3'  value='3' onClick={() =>  handleChooseExercise(3)}>Renforcement musculaire</button>
          </div>
          <div className="form-outline">
                <textarea className="form-control" id="textAreaExample" placeholder="Ajouter un commentaire(facultatif)" onBlur={handleTypeDescription}></textarea>
          </div>
          <span>Intensite</span>
          <div className="row intensity">
              <button type="button" className={`btn btn-outline-primary first  ${selectedIntensity == 1 && 'active'}`} data-mdb-ripple-color="dark" value='1' onClick={() => handleChooseIntensity(1)}>Faible</button>
              <button type="button" className={`btn btn-outline-primary second ${selectedIntensity == 2 && 'active'}`} data-mdb-ripple-color="dark" value='2' onClick={() => handleChooseIntensity(2)}>Modérée</button>
              <button type="button" className={`btn btn-outline-primary third ${selectedIntensity == 3 && 'active'}`} data-mdb-ripple-color="dark"  value='3'  onClick={() =>  handleChooseIntensity(3)}>Elevée</button>
          </div>
          <div className="form-outline">
                <textarea className="form-control" id="textAreaExample" placeholder="Ajouter un commentaire(facultatif)" onBlur={handleIntensityDescription}></textarea>
          </div>
          <div className="row series">
              <div className="col-md-2">
              <span>Séries (facultatif)</span>
              <input type="number" id="typeNumber" className="form-control"  min={0} onBlur={handleSerieNumber}/>
              </div>
              <div className="col-md-6">
              <span>Durée</span>
              <div className="form-outline">
                <input type="number" id="typeNumber" className="form-control"  min={0} onBlur={handleSerieDuration} />
                <label>minutes</label>
                </div>
              </div>
          </div>
          <span>Précautions particulières</span>
          <p className='precaution'>Souhaitez-vous ajouter des précautions particulières ?  Non <div className="form-check form-switch"><input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"  onClick={handlePrecaution}/></div> Oui</p>
          <div className={`precaution-input  ${precaution == 1 && 'active'}`}>
              <input type="text" id="typeText" className="form-control"  placeholder="Appareil locomoteur"  onBlur={handlePrecaution1} maxLength={50}/>
              <input type="text" id="typeText" className="form-control"  placeholder="Cardio-vasculaire" onBlur={handlePrecaution2} maxLength={50}/>
              <input type="text" id="typeText" className="form-control"  placeholder="Cutané et infectieux" onBlur={handlePrecaution3} maxLength={50}/>
              <input type="text" id="typeText" className="form-control"  placeholder="Chute et risque de fracture" onBlur={handlePrecaution4} maxLength={50}/>
              <input type="text" id="typeText" className="form-control"  placeholder="Autre" onBlur={handlePrecautionAutre} maxLength={50}/>
          </div>
          
    </div>
  )
}

export default Prescription