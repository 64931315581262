/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  mode: "dark" | "light";
  className: any;
}

export const HomeIndicator = ({ mode, className }: Props): JSX.Element => {
  return (
    <div className={`home-indicator ${className}`}>
      <div className={`home_indicator-2 mode-${mode}`}>{mode === "dark" && <div className="div" />}</div>
    </div>
  );
};

HomeIndicator.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]),
};
