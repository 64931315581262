import React from 'react';
import './style.css';

const Pagination = ({ postsPerPage, length, currentPage, handlePagination }) => {
  const paginationNumbers = [];

  for (let i:any = 1; i <= Math.ceil(length / postsPerPage); i++) {
    paginationNumbers.push(i);
  }

  return (
    <div className='pagination'>
      {paginationNumbers.map((pageNumber, index) => (
      <button className={`pagination-button ${currentPage === pageNumber ? 'active' : ''}`} key={pageNumber} onClick={() => handlePagination(pageNumber)}>
      {pageNumber}
    </button>))}
    </div>
  );
};

export default Pagination;