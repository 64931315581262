/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { ButtonAction } from "../ButtonAction";
import { ButtonBubble } from "../ButtonBubble";
import "./style.css";

interface Props {
  type: "variant-2" | "motivation" | "problem";
  className: any;
  buttonBubblePropertyDefaultClassName: any;
  buttonBubbleIconEssentialEmojiIconEssentialEmojiClassName: any;
  buttonBubblePropertyDefaultClassNameOverride: any;
  buttonBubbleIconEssentialShareWrapperIconEssentialShareClassName: any;
}

export const BubbleInfo = ({
  type,
  className,
  buttonBubblePropertyDefaultClassName,
  buttonBubbleIconEssentialEmojiIconEssentialEmojiClassName,
  buttonBubblePropertyDefaultClassNameOverride,
  buttonBubbleIconEssentialShareWrapperIconEssentialShareClassName,
}: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    type: type || "variant-2",
  });

  return (
    <div className={`bubble-info ${className}`}>
      {["motivation", "problem"].includes(state.type) && (
        <>
          <div className="frame-2">
            {state.type === "problem" && <div className="text-wrapper-4">Un problème ?</div>}

            {state.type === "motivation" && <p className="text-wrapper-4">Besoin d’aide pour vous motiver ?</p>}

            <p className="lorem-ipsum-dolor">
              Lorem ipsum dolor sit amet consectetur. Odio auctor sagittis cras et sit at
            </p>
            <ButtonAction
              color="light"
              size="tertiary"
              state="initial"
              text={state.type === "motivation" ? "Je me lance !" : "J’ai besoin d’en parler"}
            />
          </div>
          <ButtonBubble
            className="button-bubble-instance"
            iconEssentialCloseClassName={`${state.type === "problem" && "class-3"}`}
            iconEssentialEmojiIconEssentialEmojiClassName={`${state.type === "motivation" && "class-4"}`}
            onClick={() => {
              dispatch("click");
            }}
            property1={state.type === "motivation" ? "variant-2" : "default"}
          />
          <ButtonBubble
            className="instance-node"
            iconEssentialCloseClassName={`${state.type === "motivation" && "class-3"}`}
            iconEssentialShareWrapperIconEssentialShareClassName={`${state.type === "problem" && "class-5"}`}
            onClick={() => {
              dispatch("click_63");
            }}
            property1={state.type === "motivation" ? "default" : "variant-3"}
          />
        </>
      )}

      {state.type === "variant-2" && (
        <>
          <ButtonBubble
            className={buttonBubblePropertyDefaultClassName}
            iconEssentialEmojiIconEssentialEmojiClassName={buttonBubbleIconEssentialEmojiIconEssentialEmojiClassName}
            onClick={() => {
              dispatch("click_76");
            }}
            property1="variant-2"
          />
          <ButtonBubble
            className={buttonBubblePropertyDefaultClassNameOverride}
            iconEssentialShareWrapperIconEssentialShareClassName={
              buttonBubbleIconEssentialShareWrapperIconEssentialShareClassName
            }
            onClick={() => {
              dispatch("click_78");
            }}
            property1="variant-3"
          />
        </>
      )}
    </div>
  );
};

function reducer(state: any, action: any) {
  if (state.type === "problem") {
    switch (action) {
      case "click":
        return {
          type: "variant-2",
        };

      case "click_63":
        return {
          type: "motivation",
        };
    }
  }

  if (state.type === "motivation") {
    switch (action) {
      case "click":
        return {
          type: "problem",
        };

      case "click_63":
        return {
          type: "variant-2",
        };
    }
  }

  if (state.type === "variant-2") {
    switch (action) {
      case "click_76":
        return {
          type: "problem",
        };

      case "click_78":
        return {
          type: "motivation",
        };
    }
  }

  return state;
}

BubbleInfo.propTypes = {
  type: PropTypes.oneOf(["variant-2", "motivation", "problem"]),
};
