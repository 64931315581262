/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  notification2: boolean;
  notification: boolean;
  state: "inactive" | "active";
  className: any;
  lineClassName: any;
  line: string;
  frameClassName: any;
  text: string;
  onClick : () => void;
}

export const TabsTabItem = ({
  notification2,
  notification,
  state,
  className,
  lineClassName,
  line = "/img/line-2-3.svg",
  frameClassName,
  text = "Observance",
  onClick
}: Props): JSX.Element => {
  return (
    <div className={`tabs-tab-item ${state} ${className}`}>
      {state === "active" && <img className={`line ${lineClassName}`} alt="Line" src={line} />}

      <div
        className={`frame ${state === "active" ? frameClassName : state === "inactive" ? lineClassName : undefined}`}
      >
        <div className="observance" onClick={onClick}>{text}</div>
      </div>
      {state === "inactive" && <img className={`img ${frameClassName}`} alt="Line" src="/img/line-2-2.png" />}
    </div>
  );
};

TabsTabItem.propTypes = {
  notification2: PropTypes.bool,
  notification: PropTypes.bool,
  state: PropTypes.oneOf(["inactive", "active"]),
  line: PropTypes.string,
  text: PropTypes.string,
};
