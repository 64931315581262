import React, { useEffect, useRef, useState } from 'react'
import { Logo } from '../../components/Patients/Logo'
import './style.css'
import { api_url } from '../../globarvariable';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../../components/Medecins/SearchBar/SearchBar';
import { Centre } from '../../components/Medecins/Centre';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import Geocode from 'react-geocode';
import { getDistance } from 'geolib';
import 'leaflet/dist/leaflet.css';
import {Icon} from 'leaflet';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
const libraries = ['places'];

//Geocode.setApiKey('AIzaSyAFMZ7sAnfmjTgceZ2h4pfmkQNAmdo_F54');

export const SearchAPA = (): JSX.Element =>  {

    const [centersData, setCentersData] = useState<any[]>([]);
    const [data, setData] = useState(centersData);
    const[selected, setSelected] = useState(null);
    const accessToken = localStorage.getItem('accessToken');
    const userIdLocal = localStorage.getItem('user_id');
    const navigate = useNavigate();
    const title = 'Recherchez un centre APA';
    const [address, setAddress] = useState(null);
    const [rayon, setRayon] = useState(0);
    const [givenAddress, setGivenAddress] = useState(null);
    const [filteredAddresses, setFilteredAddresses] = useState<any[]>([]);
    const [resultNotFound, setResultNotFound] = useState(false);
    const [messageNotFound, setMessageNotFound] = useState('');
    const [distanceKm, setDistanceKm] = useState('');
    const [prescription, setPrescription] = useState(false);
    const [latitudeGivenAddress, setLatitudeGivenAddress] = useState(0);
    const [longitudeGivenAddress, setLongitudeGivenAddress] = useState(0);
    const [coordinates, setCoordinates] = useState({lat: 46.227638, lng:2.213749})
    //const mapCenter: [number, number] = [0, 0]; 
    const [mapCenter, setMapCenter] = useState<any>([46.227638, 2.213749]); // Initial center
    const [mapZoom, setMapZoom] = useState(6); 
    const [distancesArray, setDistancesArray] = useState([]);
    const [doctorName, setDoctorName] = useState('');
    const inputRef = useRef();

    const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: 'AIzaSyAFMZ7sAnfmjTgceZ2h4pfmkQNAmdo_F54',
      libraries
  });
    
  const handlePlaceChanged = () => { 
    const [ place ] = inputRef.current.getPlaces();
    if(place) { 
      setGivenAddress(place.formatted_address);
      setCoordinates({lat: place.geometry.location.lat(), lng:place.geometry.location.lng()})
    } 
}

      const handleClickNewPrescription = () => {

        navigate('/prescription-medecin');

      }

      function FlyMapTo() {

        const map = useMap()
    
        useEffect(() => {
            map.flyTo(mapCenter, mapZoom)
        }, [mapCenter])
    
        return null
    }

      const handleClickCentre = (key) => {
        if(selected === null){
          setSelected( key === selected ? null : key);
        }
        else{
          setSelected( key === selected ? 0 : key);
        }
        //localStorage.setItem('center_id', key);
          
      }

      const handleClickButton = (key) => {

        localStorage.setItem('center_id', key);
        navigate('/prescription-medecin');

      }

      /*const handleClickCentre = (key) => {
        if(selected === null){
          setSelected( key === selected ? null : key);
        }
        else{
          setSelected( key === selected ? 0 : key);
        }
    
        const formData = {
          center_id: key,
        };
    
        fetch(api_url+'api/users/'+userIdLocal, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(formData),
        })
          
      }*/

      useEffect(() => {
        const fetchData = () => {
          const response =  axios.get(api_url+'api/centers' ,{
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          }).then((response) =>{
            setCentersData(response.data.centers);
            setData(response.data.centers)
          })
          //
        }
    
        fetchData();

    
      }, []);


      /*const filteredByAddressList = () => {
              return data.filter(item => {
                const addressMatch =  item.address.toLowerCase().includes(address.toLocaleLowerCase())
                const cityMatch =  item.city.toLowerCase().includes(city.toLocaleLowerCase())
              
                return addressMatch && cityMatch;
              })
      }
  
      useEffect(() => {
          if(address || city){
            setCentersData(filteredByAddressList);
          }
          else{
            setCentersData(data);
          }
      }, [address, city]);
    

      const handleChange = (e) => {
          setAddress(e.target.value)
      }*/

      const handleFilter = async () => {
        const { lat, lng } = coordinates
        setLatitudeGivenAddress(lat);
        setLongitudeGivenAddress(lng);
        const filtered = centersData.filter((address) => {
          const latitude = address.latitude; 
          const longitude = address.longitude;// Provide coordinates for each address
          const name = address.name;
          const phone = address.phone;
          const distance = getDistance(
            { latitude: lat, longitude: lng },
            { latitude: latitude, longitude: longitude}
          );

          if(rayon != 0){
            return distance <= rayon * 1000;
            
          }
          else{
            return distance
          }
        });

        setMapZoom(prevZoom => 12);
        setMapCenter(prevCenter=> [lat, lng])
    
        const sorted = filtered.sort((a, b) => {
          const distanceA = getDistance(
            { latitude: lat, longitude: lng },
            { latitude: a.latitude, longitude: a.longitude }
          );
  
          const distanceB = getDistance(
            { latitude: lat, longitude: lng },
            { latitude: b.latitude, longitude: b.longitude }
          );

          return distanceA - distanceB;
        }
        );

        const distances = sorted.map((center) => {
          const latitude = center.latitude;
          const longitude = center.longitude;
          const distance = getDistance(
            { latitude: lat, longitude: lng },
            { latitude, longitude }
          );
          // Return true for items that meet your distance criteria
          const result = distance / 1000;
          return result.toFixed(1);
        });

        setDistancesArray(distances);

        if(filtered.length == 0){
            setResultNotFound(true);
            setMessageNotFound('Pas de résultat');
            setFilteredAddresses(sorted);
        }
        else{
          setResultNotFound(false);
          setFilteredAddresses(sorted);
        }
    
      };

      const handlePlaceSelect = (place) => {
        setGivenAddress(place.label);
      };

      /*function initMap(): void {
        const bounds = new google.maps.LatLngBounds();
        const markersArray: google.maps.Marker[] = [];
      
        const map = new google.maps.Map(
          document.getElementById("map") as HTMLElement,
          {
            center: { lat: 0, lng: 0 },
            zoom: 10,
          }
        );
      
        // initialize services
        const geocoder = new google.maps.Geocoder();
        const service = new google.maps.DistanceMatrixService();
      
        // build request
        const origin1 = { lat: 55.93, lng: -3.118 };
        const origin2 = "Greenwich, England";
        const destinationA = "Stockholm, Sweden";
        const destinationB = { lat: 50.087, lng: 14.421 };
        const origin = givenAddress;
        const destination = centersData;
      
        const request = {
          origins: [origin],
          destinations: destination,
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        };
      
        // put request on page
        (document.getElementById("request") as HTMLDivElement).innerText =
          JSON.stringify(request, null, 2);
      
        // get distance matrix response
        service.getDistanceMatrix(request).then((response) => {
          // put response
          (document.getElementById("response") as HTMLDivElement).innerText =
            JSON.stringify(response, null, 2);
      
          // show on map
          const originList = response.originAddresses;
          const destinationList = response.destinationAddresses;
      
          deleteMarkers(markersArray);
      
          const showGeocodedAddressOnMap = (asDestination: boolean) => {
            const handler = ({ results }: google.maps.GeocoderResponse) => {
              map.fitBounds(bounds.extend(results[0].geometry.location));
              markersArray.push(
                new google.maps.Marker({
                  map,
                  position: results[0].geometry.location,
                  label: asDestination ? "D" : "O",
                })
              );
            };
      
            return handler;
          };
      
          for (let i = 0; i < originList.length; i++) {
            const results = response.rows[i].elements;
      
            geocoder
              .geocode({ address: originList[i] })
              .then(showGeocodedAddressOnMap(false));
      
            for (let j = 0; j < results.length; j++) {
              geocoder
                .geocode({ address: destinationList[j] })
                .then(showGeocodedAddressOnMap(true));
            }
          }
        });
      }
      
      function deleteMarkers(markersArray: google.maps.Marker[]) {
        for (let i = 0; i < markersArray.length; i++) {
          markersArray[i].setMap(null);
        }
      
        markersArray = [];
      }
      
      useEffect(() => {
        initMap;
    }, []);*/

    const handleMarkerClick = (markerId) => {
      // Handle the marker click action here
      setSelected(markerId);
    };

    const logout = () => {
      confirmAlert({
        title: 'Confirmation',
        message: 'Êtes-vous sûr de vouloir vous déconnecter ?',
        buttons: [
          {
            label: 'Non',
            className: 'logout-no',
            onClick: () => window.location.reload()
          },
          {
            label: 'Oui',
            className: 'logout-yes',
            onClick: () => {
              localStorage.removeItem('accessToken');
              navigate('/');
            }
          }
        ]
      })
  }

  useEffect(() => {
    const  extractInitials = (name) => {
      // Split the name into separate words
      const words = name.split(' ');
      
      // Extract the first letter of each word
      const initials = words.map(word => word.charAt(0));
      
      // Concatenate the initials
      return initials.join('');
    }

    setDoctorName(extractInitials(localStorage.getItem('doctor')));
  }, []);

  return (
    <div>
      <script src="https://polyfill.io/v3/polyfill.min.js?features=default"></script>
 <header className="p-3 mb-3 sapa">
    <div className="container">
      <div className="row">
        <div className='col-md-3'>
        <a href="/medecin" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
        <Logo
          className="class logo-auth"
          property1="default"
          propertyDefault= "/img/logo-1.svg"
        /></a>
        </div>
        <div className='col-md-9'>
        <div className="dropdown text-end">
        <button type="button" className="btn btn-primary new-prescription" data-mdb-ripple-color="dark" onClick={handleClickNewPrescription}>Nouvelle prescription <img src="/img/add-circle.png" /></button>
         <img src='/img/messages.svg' className='message' /> 
          <a href="#" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
            {doctorName}
          </a>
          <img src="/img/logout.svg" alt="mdo" width="32" height="32" className="rounded-circle logout"  onClick={logout}/>
        </div>
        </div>
      </div>
    </div>
  </header>   
  <div className="container searchAPA">    
  <div className='address'>
    <h2> Recherchez un centre APA</h2>
      <div className='form'>
          <div>
          {/* <GooglePlacesAutocomplete apiKey='AIzaSyAFMZ7sAnfmjTgceZ2h4pfmkQNAmdo_F54'  selectProps={{
            placeholder: 'Entrer une adresse',
            givenAddress,
            onChange : handlePlaceSelect,
            noOptionsText: 'No suggestions available'            
          }}/> */}

            {isLoaded
        &&
        <StandaloneSearchBox
            onLoad={ref => inputRef.current = ref}
            onPlacesChanged={handlePlaceChanged}
        >
            <input
                type="text"
                className="form-control searchaddress"
                placeholder="Entrez une adresse"
            />
        </StandaloneSearchBox>
}
          <div className='slider'>
            <p className='rayon'>Dans un rayon de {rayon} Km</p>
          <input type="range" min='0' max='50' step='5' value={rayon} onChange={(e:any) => setRayon(e.target.value)}/>
              <ul id="tickmarks">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            </div>
          <button className='btn btn-primary' onClick={handleFilter}>Rechercher</button></div>
      </div>
  </div>
  {resultNotFound === true && <p className='resultNotFound'>{messageNotFound}</p>}
  { filteredAddresses.map((address, index) => (
  <Centre className={`${selected == address.id && 'active'}`} line="/img/line-10-2.svg" property1="default" key='1' name={address.name}  address={address.address} postal_code={address.postal_code} city={address.city} carte={false} prescription={true} distance={distancesArray[index]} activities={address.activities} onClick={() => handleClickCentre(address.id)} onClickButton={() => handleClickButton(address.id)}/>
  )) }

  <MapContainer center={mapCenter} zoom={mapZoom} scrollWheelZoom={true}>
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />

{ filteredAddresses.map((address) => (
      <div>
          <Marker position={[address.latitude, address.longitude]} icon={new Icon({iconUrl: "/img/marker-icon.png", iconSize: [25, 41], iconAnchor: [12, 41]})}  eventHandlers={{
            click: () => handleMarkerClick(address.id),
          }}>
            <Popup>
              {address.name} <br /> {address.phone_number}
            </Popup>
          </Marker>
          </div>
          )) }
          <Marker position={[latitudeGivenAddress, longitudeGivenAddress]} icon={new Icon({iconUrl: "/img/marker-icon-red.png", iconSize: [25, 41], iconAnchor: [12, 41]})} >
            <Popup>
              {givenAddress}
            </Popup>
          </Marker>
          <FlyMapTo />
      </MapContainer>
</div>
    </div>
  );
};