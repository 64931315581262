/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ButtonAction } from "../ButtonAction";
import { IconEssentialTick } from "../IconEssentialTick";
import "./style.css";
import { api_url } from "../../../globarvariable";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface Props {
  iconLeft: boolean;
  iconLeft2: boolean;
  iconRight: boolean;
  state: "done" | "in-progress" | "to-do";
  type: "patient" | "prescription" | "centre-APA";
  className: any;
  frameClassName: any;
  text: string;
  renseignezLesClassName: any;
  iconEssentialTickIconEssentialTickClassName: any;
  text1: string;
}

export const Steps = ({
  iconLeft,
  iconLeft2 = true,
  iconRight,
  state,
  type,
  className,
  frameClassName,
  text = "COORDONNÉES DU PATIENT",
  renseignezLesClassName,
  iconEssentialTickIconEssentialTickClassName,
  text1 = "Renseignez les coordonnées",
}: Props): JSX.Element => {

  const[infoPatient, setInfoPatient] = useState(null);
  const[infoCenter, setInfoCenter] = useState(null);
  const userId = useSelector((action : any) => action.userIdReducer);
  const centerId = useSelector((action : any) => action.centerIdReducer);
  const accessToken = localStorage.getItem('accessToken');
  const id = localStorage.getItem('user_id');
  const center_id = localStorage.getItem('center_id');
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(api_url+'api/users/'+id, {
          headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${accessToken}`,
          },
        });
        const jsonData = await response.json();
        setInfoPatient(jsonData);
        
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchDataCenter = async () => {
      try {
        const response = await fetch(api_url+'api/centers/'+center_id, {
          headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${accessToken}`,
          },
        });
        const jsonData = await response.json();
        setInfoCenter(jsonData);
        
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
    fetchDataCenter();
  }, []);

  const clickStep = () => {
      if(type === "patient"){
          navigate('/prescription-medecin');
      }
      else if(type === "centre-APA"){
         navigate('/medecin/choisir-un-centre');
      }
      else{
          navigate('medecin/prescription');
      }
  }

  return (
    <div className={`steps ${state} ${className}`} onClick={clickStep}>
      <div className={`frame ${frameClassName}`}>
        <div className="COORDONN-ES-PATIENT">
          {state === "done" && type === "patient" && <>COORDONNÉES PATIENT</>}

          {type === "centre-APA" && ["done", "in-progress"].includes(state) && <>CHOIX DU CENTRE APA</>}

          {type === "prescription" && <>PRESCRIPTION</>}

          {type === "patient" && ["in-progress", "to-do"].includes(state) && <>{text}</>}

          {state === "to-do" && type === "centre-APA" && <>CHOIX DU CENTRA APA</>}
        </div>
        {["in-progress", "to-do"].includes(state) && (
          <div className={`renseignez-les ${renseignezLesClassName}`}>
            {type === "patient" && <>{text1}</>}

            {type === "centre-APA" && <>Choisissez le centre</>}

            {type === "prescription" && <>Éditez la prescription</>}
          </div>
        )}

        {state === "done" && (
          <div className={`marie-christine ${type}`}>
            {type === "patient" &&  infoPatient && <>{infoPatient.first_name} {infoPatient.last_name}</>}

            {type === "centre-APA" && infoCenter && <>{infoCenter.name}</>}

            {type === "prescription" && <>Le 12 août 2023</>}
          </div>
        )}

        {state === "done" && ["centre-APA", "patient"].includes(type) && (
          <>
            <div className={`mariechristine type-${type}`}>
              {type === "patient" && infoPatient && <>{infoPatient.email} </>}

              {type === "centre-APA" && infoCenter &&(
                <>
                  <div className="icon-location" />
                  <p className="element-rue-henry-litollf">
                    {infoCenter.address}
                    <br />
                    {infoCenter.postal_code} {infoCenter.city}
                  </p>
                </>
              )}
            </div>
            <div className="div">
              {type === "patient" && infoPatient && (
                <>
                  <div className="icon-location" />
                  <p className="element-rue-henry-litollf">
                  {infoPatient.address}
                    <br />
                    {infoPatient.postal_code} {infoPatient.city}
                  </p>
                </>
              )}

              {type === "centre-APA" && infoCenter &&(
                <>
                  <a href={`tel:${infoCenter.phone_number} `} className="btn btn-primary" data-mdb-ripple-color="dark">Contacter <img src='/img/call.png' /> </a>
                </>
              )}
            </div>
          </>
        )}

        {state === "done" && type === "prescription" && (
          <>
            <div className="element-semaines">28 semaines</div>
            <div className="frame-2">
              <div className="nom-de-l-activit">Nom de l’activité</div>
              <div className="element-s">2/s</div>
            </div>
            <div className="frame-2">
              <div className="nom-de-l-activit">Nom de l’activité</div>
              <div className="element-s">2/s</div>
            </div>
          </>
        )}
      </div>
      <IconEssentialTick className={iconEssentialTickIconEssentialTickClassName} />
    </div>
  );
};

Steps.propTypes = {
  iconLeft: PropTypes.bool,
  iconLeft2: PropTypes.bool,
  iconRight: PropTypes.bool,
  state: PropTypes.oneOf(["done", "in-progress", "to-do"]),
  type: PropTypes.oneOf(["patient", "prescription", "centre-APA"]),
  text: PropTypes.string,
  text1: PropTypes.string,
};
