import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from 'react-signature-canvas';
import { BottomButtons } from "../../components/Medecins/BottomButtons";
import { ButtonAction } from "../../components/Medecins/ButtonAction";
import { Steps } from "../../components/Medecins/Steps";
import { TagSelection } from "../../components/Medecins/TagSelection";
import { TextFieldBasic } from "../../components/Medecins/TextFieldBasic";
import { TextFieldNumber } from "../../components/Medecins/TextFieldNumber";
import "./style.css";
import Prescription from "../../components/Medecins/Prescription/Prescription";
import FinalPrescription from "../../components/Medecins/FinalPrescription/FinalPrescription";
import { api_url } from "../../globarvariable";
import { Logo } from "../../components/Patients/Logo";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import html2canvas from "html2canvas";
import domtoimage from 'dom-to-image';

export const FinalisationPrescription = (): JSX.Element => {

  
  const signatureRef = useRef();
  const canvasRef = useRef(null);
  const accessToken = localStorage.getItem('accessToken');
  const prescriptionId = localStorage.getItem('prescriptionId');
  const doctor = localStorage.getItem('doctor');
  const [infoPrescription, setInfoPrescription] = useState();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  /*useEffect(() => {
    window.scrollTo(0, 0);

    const canvas = canvasRef.current;
    //const canvas = document.getElementById('myCanvas');
    const ctx = canvas.getContext('2d');

    // Set font and text properties
    ctx.font = '40px biographyregular';
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Text to be drawn
    const text = doctor;

    // X and Y coordinates for the text
    const x = ctx.width / 2; // Centered horizontally
    const y = ctx.height / 2; // Centered vertically

    // Draw the text
    ctx.fillText(text, 180, 90);

  }, []);*/

    const [text, setText] = useState('Signer');
    const [message, setMessage] = useState(false);
    const [textMessage, setTextMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);
    const [fileObjects, setFileObject] = useState<File | undefined>(undefined);;
    const navigate = useNavigate();
  
    const clearSignature = () => {
      signatureRef.current.clear();
      setText('Signer');
    };

    const PatientName = localStorage.getItem('name');
    
  const [disabledButton, setDisabledButton] = useState(true);

  const handleCheckbox = () => {
      setDisabledButton(false);
      
  }

      // Function to convert data URL to Blob
      const dataURLtoBlob = (dataUrl) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
    
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
    
        return new Blob([u8arr], { type: mime });
      };



  const downloadPrescription = async () => {
    setLoading(true);
    domtoimage.toPng(canvasRef.current, { width: 506, height: 120 }).then((canvas) => {
      
      
      const blob = dataURLtoBlob(canvas);
  
      // Step 3: Create a new File object from the Blob
      const fileObject = new File([blob], 'signature.png');
  
      const formData = new FormData();
      formData.append('signature', fileObject);
  
      fetch(api_url+'api/prescriptions/'+prescriptionId+'/sign', {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData
      }).then((response) => {
          if(disabledButton === true){
          setDisabledButton(false);
          }
      }
        ).catch((error) => {
        console.error('Error fetching data:', error);
      });
  
      localStorage.removeItem("center_id");
    })
      
    const response = await fetch(api_url+'api/prescriptions/'+prescriptionId+'/download', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    }).then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', PatientName+'.pdf');
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error downloading PDF:', error);
    });


  }



  const sendPrescription = () => {
    setLoadingSend(true);
    domtoimage.toPng(canvasRef.current, { width: 506, height: 120 }).then((canvas) => {
  
      // Step 3: Create a new File object from the Blob*/

      const blob = dataURLtoBlob(canvas);

      const fileObject = new File([blob], 'signature.png');
      
  
      const formData = new FormData();
      formData.append('signature', fileObject);
  
     fetch(api_url+'api/prescriptions/'+prescriptionId+'/sign', {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData
      }).then((response) => {
          if(disabledButton === true){
          setDisabledButton(false);
          }
          else{
            setDisabledButton(true);
          }

          const formData = {
            available: true,
          };
      
          fetch(api_url+'api/prescriptions/'+prescriptionId, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(formData),
          })
            .then((response) => response.json())
            .then((data) => {
              if(data.id){
                setLoadingSend(false);
                 setMessage(true);
                 setTextMessage('La prescription a été envoyée avec succès au patient');
                 setTimeout(() => {
                  navigate('/medecin');
                }, 3000);
              }
            })
            .catch((error) => {
              console.error('Error inserting data:', error);
            });
      }
        ).catch((error) => {
        console.error('Error fetching data:', error);
      });
  
      localStorage.removeItem("center_id");
      })

  }



  const handleSignature = () => {

    html2canvas(canvasRef.current).then((canvas) => {
      
    const signatureData = canvas.toDataURL();
    
    // Step 2: Convert the base64 data to a Blob
    const byteString = atob(signatureData.split(',')[1]);
    const mimeString = signatureData.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });

    // Step 3: Create a new File object from the Blob
    const fileObject = new File([blob], 'signature.png', { type: mimeString });

    const formData = new FormData();
    formData.append('signature', fileObject);

    fetch(api_url+'api/prescriptions/'+prescriptionId+'/sign', {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData
    }).then((response) => {
        if(disabledButton === true){
        setDisabledButton(false);
        }
        else{
          setDisabledButton(true);
        }
        alert('Votre prescription a été signé avec succès')
    }
      ).catch((error) => {
      console.error('Error fetching data:', error);
    });

    localStorage.removeItem("center_id");
    })

  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      // Define your scroll condition here
      if(scrollTop > 200){
        setIsScrolled(true);
      } 
      else{
        setIsScrolled(false);
      }
      
    };

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  return (
    <>
    <header className="p-3 mb-3 prescription-header">
    <a href="/medecin" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
        <Logo
          className="class logo-auth"
          property1="default"
          propertyDefault= "/img/logo-1.svg"
        /></a>
  </header> 
    <div className="donnes-prescription">
      <div className="div-2">
      <div className="row">
      <div className="col-md-3">
        <Steps
          className={`steps-instance ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="steps-2"
          frameClassNameOverride="steps-4"
          iconEssentialTickIconEssentialTickClassName="steps-6"
          iconLocationIconLocationClassName="steps-5"
          marieChristineClassName="steps-3"
          mariechristineClassName="steps-3"
          state="done"
          text="PATIENT"
          type="patient"
        />
        <Steps
          className={`steps-7 ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="steps-2"
          frameClassNameOverride="steps-4"
          iconEssentialTickIconEssentialTickClassName="steps-6"
          iconLocationIconLocationClassNameOverride="steps-5"
          marieChristineClassName="steps-3"
          mariechristineClassName="steps-3"
          state="done"
          type="centre-APA"
        />
        <Steps
          className={`steps-9 ${isScrolled ? 'scrolled' : ''}`}
          frameClassName="steps-2"
          iconEssentialTickIconEssentialTickClassName="steps-10"
          renseignezLesClassName="steps-3"
          state="in-progress"
          type="prescription"
        />
      </div>
      <div className="col-md-1">

      </div>
      <div className="col-md-8 finalisation-inscription-text">

      { message === true && <div className="followed">
            <p><strong>{textMessage}</strong></p>
        </div>}
          <FinalPrescription />
          
        <div className='check'>
            <input type='checkbox' className='checkbox' onChange={handleCheckbox}/>
            <strong>Je certifie être responsable de la prescription médicale d'activité physique adaptée</strong>
        </div>

        <div id='myCanvas' className="signature-canvas'" ref={canvasRef}>{doctor}</div>
        <div className="note-signature">
        <p>La dispensation de l’activité physique adaptée ne peut donner lieu à ce jour à une prise en charge financière par l’assurance maladie. </p>
        <p>1 Décret n° 2016-1990 du 30 décembre 2016 relatif aux conditions de dispensation de l’activité physique adaptée prescrite par le médecin traitant à des patients atteints d’une maladie chronique.</p>
        </div>
        </div>
      </div>
      <div className="row bottom-buttons">
        <div className="col-md-12">
        
                <button type="button" className="btn btn-outline-primary" data-mdb-ripple-color="dark" disabled={disabledButton} onClick={downloadPrescription}>{loading && <ClipLoader color="white" loading={loading} />} Télécharger la prescription <img src='/img/document-download.png' /></button>
                <button type="button" className="btn btn-primary" data-mdb-ripple-color="dark" disabled={disabledButton} onClick={sendPrescription}>{loadingSend && <ClipLoader color="white" loading={loading} />} Signer et envoyer la prescription <img src='/img/send-2.png'></img></button>
        </div>
    </div>
    </div>
    </div>
    </>
  );
};
