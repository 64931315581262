/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { TextFieldNumber } from "../TextFieldNumber";
import "./style.css";

interface Props {
  state: "filled" | "initial";
  className: any;
  handleValueChange: any;
}

export const TextFieldCode = ({ state, className, handleValueChange }: Props): JSX.Element => {
  const numOfFields = 3;
  const inputRef = useRef(null);
  const handleChange = (e:any) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
  
    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 6) {
        // Get the next input field
        const nextSibling = document.querySelector<HTMLInputElement>(
          `input[name=code-${parseInt(fieldIndex, 10) + 1}]`
        );
  
        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  }

  const[code1,setCode1] = useState('');
  const[code2,setCode2] = useState('');
  const[code3,setCode3] = useState('');
  const[code4,setCode4] = useState('');
  const[code5,setCode5] = useState('');
  const[code6,setCode6] = useState('');

  const handleInputChange1 = (event) => {
    const value = event.target.value;
    setCode1(value);
  };

  const handleInputChange2 = (event) => {
    const value = event.target.value;
    setCode2(value);
  };

  const handleInputChange3 = (event) => {
    const value = event.target.value;
    setCode3(value);
  };

  const handleInputChange4 = (event) => {
    const value = event.target.value;
    setCode4(value);
  };

  const handleInputChange5 = (event) => {
    const value = event.target.value;
    setCode5(value);
  };

  const handleInputChange6 = (event) => {
    const value = event.target.value;
    setCode6(value);
  };

  if(code1 != ''  && code2 != ''  && code3 != '' && code4 != '' && code5 !='' && code6 !=''){
    const sixcode = code1+code2+code3+code4+code5+code6;
    handleValueChange(sixcode);
  }

  return (
    <div className={`text-field-code ${className}`}>
      <TextFieldNumber className="text-field-number-caps" property1={state === "filled" ? "filled" : "initial"} rank={1} onChange={handleChange}  onBlur={handleInputChange1}/>
      <TextFieldNumber className="text-field-number-caps" property1={state === "filled" ? "filled" : "initial"} rank={2} onChange={handleChange}  onBlur={handleInputChange2}/>
      <TextFieldNumber className="text-field-number-caps" property1={state === "filled" ? "filled" : "initial"} rank={3} onChange={handleChange}  onBlur={handleInputChange3}/>
      <TextFieldNumber className="text-field-number-caps" property1={state === "filled" ? "filled" : "initial"} rank={4} onChange={handleChange}  onBlur={handleInputChange4}/>
      <TextFieldNumber className="text-field-number-caps" property1={state === "filled" ? "filled" : "initial"} rank={5} onChange={handleChange}  onBlur={handleInputChange5}/>
      <TextFieldNumber className="text-field-number-caps" property1={state === "filled" ? "filled" : "initial"} rank={6} onChange={(e) => setCode6(e.target.value)}  onBlur={handleChange}/>
    </div>
  );
};

TextFieldCode.propTypes = {
  state: PropTypes.oneOf(["filled", "initial"]),
};
