const initialState = null;
const CenterIdReducer = (state = initialState, action) => {
    switch(action.type){
        case 'centerId' : 
            return action.payload
        default:    
            return state
    }
    }

export default CenterIdReducer;
    