/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  state: "image" | "empty";
  size: "big" | "medium" | "small";
  className: any;
  name: String;
}

export const ProfilePicture = ({ state, size, className,name }: Props): JSX.Element => {
  return (
    <div className={`profile-picture ${size} ${state} ${className}`}>
      {state === "empty" && <div className="SL">{name}</div>}
    </div>
  );
};

ProfilePicture.propTypes = {
  state: PropTypes.oneOf(["image", "empty"]),
  size: PropTypes.oneOf(["big", "medium", "small"]),
};
