/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useState } from "react";
import { api_url } from "../../globarvariable";
import ModalCentered from "../../components/Patients/Modal/Modal";

interface Props {
  className: any;
  rating_id: any;
}

export const Group5 = ({ className, rating_id }: Props): JSX.Element => {
  const accessToken = localStorage.getItem('accessToken');
  const [showModal, setShowModal] = useState(false);

  const handleClickReporting = (rate) => {
    const formData = {
      rate: rate,
    };

    fetch(api_url+'api/ratings/'+rating_id+'/rate', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json())
    .then((data) => {
        if(data.id){
            setShowModal(true)
        }
    })
  }

  const onClose = () => {
    setShowModal(false)
    window.location.reload();
  }
 
  return (
    <svg
      className={`group-5 ${className}`}
      fill="none"
      height="70"
      viewBox="0 0 71 70"
      width="71"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => handleClickReporting('Pas bien')}
    >
      <rect className="rect" fill="#F9F9F9" height="69.6947" rx="16" width="69.6947" />
      <path
        className="path"
        d="M47.6 10.3073C42.3608 10.3317 37.5431 13.1602 35 17.704C31.8342 11.9918 25.1699 9.1329 18.8062 10.7564C12.442 12.3798 7.99517 18.0737 8 24.5924C8 39.9273 26.5256 60.3052 35 60.3052C43.4744 60.3052 62 39.9273 62 24.5924C61.9908 16.7066 55.5488 10.3165 47.6 10.3073ZM35 58.5195C28.25 58.5195 9.8 39.2851 9.8 24.5924C9.79649 18.5642 14.1326 13.3934 20.1083 12.2996C26.0839 11.2063 31.9946 14.502 34.1593 20.1344C34.2925 20.4801 34.6269 20.7081 35 20.7081C35.3731 20.7081 35.7075 20.4801 35.8407 20.1344C38.0054 14.502 43.9161 11.2063 49.8917 12.2996C55.8674 13.3934 60.2035 18.5642 60.2 24.5924C60.2 39.2851 41.75 58.5195 35 58.5195Z"
        fill="#FF852C"
      />
      <path
        className="path"
        d="M28 28.8052C28 30.1858 26.6567 31.3052 25 31.3052C23.3433 31.3052 22 30.1858 22 28.8052C22 27.4242 23.3433 26.3052 25 26.3052C26.6567 26.3052 28 27.4242 28 28.8052Z"
        fill="#FF852C"
      />
      <path
        className="path"
        d="M48 28.8052C48 30.1858 46.6567 31.3052 45 31.3052C43.3433 31.3052 42 30.1858 42 28.8052C42 27.4242 43.3433 26.3052 45 26.3052C46.6567 26.3052 48 27.4242 48 28.8052Z"
        fill="#FF852C"
      />
      <path
        className="path"
        d="M47.1333 38.3052H22.8667C22.3881 38.3052 22 38.7524 22 39.3052C22 39.8574 22.3881 40.3052 22.8667 40.3052H47.1333C47.6119 40.3052 48 39.8574 48 39.3052C48 38.7524 47.6119 38.3052 47.1333 38.3052Z"
        fill="#FF852C"
      />
        <ModalCentered
        show={showModal}
        onHide={() => onClose()}
        title= 'Félicitations !'
        subtitle= 'Evaluation terminée'
        message='Votre évaluation a bien été prise en compte. Merci !'
      />
    </svg>
  );
};
