import React, { useEffect, useState } from "react";
import { ButtonAction } from "../../components/Patients/ButtonAction";
import { HeaderMobile } from "../../components/Patients/HeaderMobile";
import { HomeIndicator } from "../../components/Patients/HomeIndicator";
import { TextFieldCode } from "../../components/Patients/TextFieldCode";
import "./style.css";
import { Navigate, useNavigate } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { InscriptionPatientMobile } from "../InscriptionPatientMobile";
import { api_url } from "../../globarvariable";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { getUserId } from "../../actions";

export const OnboardingEAPA = (): JSX.Element => {
  const[stateButton, setStateButton] = useState('initial');
  const[error, setError] = useState(false);
  const[errorMessage, setErrorMessage] = useState('');
  const [childOfChildValue, setChildOfChildValue] = useState('');
  const [userIdParams, setuserIdParams] = useState('');
  const [code, setCode] = useState('');
  const accessToken = localStorage.getItem('accessToken');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const key = searchParams.get('key');

  const handleChildOfChildValueChange = (value) => {
    setChildOfChildValue(value);
  };

  const handleNavigation = () => {
    setLoading(true);
    const formData = {
      code: childOfChildValue,
    };

    fetch(api_url+'api/eapas/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${key}`,
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json()).then((data) => {
      if(data.id){
        dispatch(getUserId(data.id));
        localStorage.setItem('idEapa', data.id);
        localStorage.setItem('accessToken', data.token);
        navigate('/password-eapa');
      }
      else{
          setError(true);
          setErrorMessage('Votre code est éronné')
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    })
    .catch((error) => {
      console.error('Error data:', error);
    });

  }

  return (
    <div className="onboarding-mobile">
      <div className="div-2">
        <p className="text-wrapper">Bienvenue sur votre espace APA</p>
        <p className="merci-de-rentrer">Merci de rentrer votre code envoyé sur l&#39;email s*****@g***.com</p>
        <div className="group">
          <p className="vous-n-avez-plus-de">
            Vous n’avez plus de code ?<br />
            Pas de panique ! Nous vous le renvoyons par SMS
          </p>
          <div className="text-wrapper-2">Renvoyer le code</div>
        </div>
        
       <ButtonAction
          className="button-action-instance onboarding"
          color="dark"
          size="primary"
          state={stateButton == "disabled" ? "disabled" : "initial"}
          text="Continuer"
          phoneNumber=""
          onClick={handleNavigation}
        />
        {loading && <ClipLoader className='loader' color="#123abc" loading={loading} />}
        <div className="keyboard">
          <div className="overlap">
            <HomeIndicator className="home-indicator-instance" mode="light" />
          </div>
        </div>
        <TextFieldCode className="text-field-code-number" state="initial" handleValueChange={handleChildOfChildValueChange}/>
        {errorMessage != '' && <p className="error-message">{errorMessage}</p> }
        <HeaderMobile className="header-mobile-instance" logoPropertyDefault="/img/logo.svg" type="logo" />
      </div>
    </div>
  );
};
