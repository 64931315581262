/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

interface Props {
  className: any;
  onClick : any;
}

export const IconTimeCalendar = ({ className, onClick }: Props): JSX.Element => {
  return <div className={`icon-time-calendar ${className}`}  onClick={onClick}/>;
};
