import React, { Component, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, redirect, useLocation} from 'react-router-dom';
import { OnboardingMobile } from './screens/OnboardingMobile/OnboardingMobile';
import { InscriptionPatientMobile } from './screens/InscriptionPatientMobile';
import { ObservanceMobile } from './screens/ObservanceMobile';
import { DonnesPatient } from './screens/DonnesPatient/DonnesPatient';
import { DonnesCentre } from './screens/DonnesCentre/DonnesCentre';
import { DonnesPrescription } from './screens/DonnesPrescription/DonnesPrescription';
import SignIn from './screens/SignIn/SignIn';
import ProtectedRoute from './ProtectedRoute';
import { useSelector } from 'react-redux';
import { FinalisationPrescription } from './screens/FinalisationPrescription/FinalisationPrescription';
import { SearchPatient } from './screens/SearchPatient';
import { SearchAPA } from './screens/SearchAPA';
import Goal from './screens/Goal/Goal';
import SignInPatient from './screens/SignInPatient/SignInPatient';
import AdminDashboard from './screens/Admin/AdminDashboard';
import Eapa from './screens/EAPA/Eapa';
import SignInEapa from './screens/SignInEapa/SignInEapa';
import ForgottenPassword from './screens/ForgottenPassword/ForgottenPassword';
import ResetPassword from './screens/ResetPassword/ResetPassword';
import { InscriptionEAPA } from './screens/InscriptionEAPA/InscriptionEAPA';
import { OnboardingEAPA } from './screens/OnboardingEAPA/OnboardingEAPA';
import CalendarPage from './components/Patients/Calendar/Calendar';
import { PasswordEapa } from './screens/PasswordEapa/PasswordEapa';
import CalendarEAPA from './components/Patients/CalendarEAPA/CalendarEAPA';
import PSC from './components/Medecins/PSC/PSC';
import Login from './screens/Login/Login';

const App = () => {
  const accessToken = localStorage.getItem('accessToken');
  const logged = useSelector((action : any) => action.loggedReducer);
  const isLoggedIn = Boolean(accessToken); 

  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn/>}></Route>
        <Route path="/admin" element={<AdminDashboard/>}></Route>
        <Route path="/eapa" element={<ProtectedRoute isLoggedIn={isLoggedIn}><Eapa/></ProtectedRoute>}></Route>
        <Route path="/login" element={<SignInPatient/>}></Route>
        <Route path="/login-medecin" element={<Login/>}></Route>
        <Route path="/psc" element={<PSC/>}></Route>
        <Route path="/login-eapa" element={<SignInEapa/>}></Route>
        <Route path="/code-patient" element={<OnboardingMobile/>}></Route>
        <Route path="/code-eapa" element={<OnboardingEAPA/>}></Route>
        <Route path="/password-eapa" element={<PasswordEapa/>}></Route>
        <Route path="/inscription-patient" element={<InscriptionPatientMobile/>}></Route>
        <Route path="/inscription-eapa" element={<InscriptionEAPA/>}></Route>
        <Route path="/patient" element={<ProtectedRoute isLoggedIn={isLoggedIn}><ObservanceMobile/></ProtectedRoute>}></Route>
        <Route path="/goal" element={<ProtectedRoute isLoggedIn={isLoggedIn}><Goal/></ProtectedRoute>}></Route>
        <Route path="/calendar" element={<ProtectedRoute isLoggedIn={isLoggedIn}><CalendarPage/></ProtectedRoute>}></Route>
        <Route path="/calendarEAPA" element={<ProtectedRoute isLoggedIn={isLoggedIn}><CalendarEAPA/></ProtectedRoute>}></Route>
        <Route path="/forgot-password" element={<ForgottenPassword/>}></Route>
        <Route path="/reset-password" element={<ResetPassword/>}></Route>
        <Route path="/medecin" element={<ProtectedRoute isLoggedIn={isLoggedIn}><SearchPatient/></ProtectedRoute>}></Route>
        <Route path="/searchAPA" element={<ProtectedRoute isLoggedIn={isLoggedIn}><SearchAPA/></ProtectedRoute>}></Route>
        <Route path="/prescription-medecin" element={<ProtectedRoute isLoggedIn={isLoggedIn}><DonnesPatient/></ProtectedRoute>}></Route>
        <Route path="/medecin/choisir-un-centre" element={<ProtectedRoute isLoggedIn={isLoggedIn}><DonnesCentre/></ProtectedRoute>}></Route>
        <Route path="/medecin/prescription" element={<ProtectedRoute isLoggedIn={isLoggedIn}><DonnesPrescription/></ProtectedRoute>}></Route>
        <Route path="/medecin/final-prescription" element={<ProtectedRoute isLoggedIn={isLoggedIn}><FinalisationPrescription/></ProtectedRoute>}></Route>
      </Routes>
    </BrowserRouter>
  </>
  )
}

export default App