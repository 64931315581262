/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useState } from "react";
import { api_url } from "../../globarvariable";
import './style.css';
import ModalCentered from "../../components/Patients/Modal/Modal";
import { useNavigate } from "react-router-dom";

interface Props {
  className: any;
  rating_id: any;
}

export const ButtonEvaluation = ({ className, rating_id }: Props): JSX.Element => {

  const accessToken = localStorage.getItem('accessToken');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleClickReporting = (rate) => {
    const formData = {
      rate: rate,
    };

    fetch(api_url+'api/ratings/'+rating_id+'/rate', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json())
    .then((data) => {
        if(data.id){
            setShowModal(true)
        }
    })
  }

  const onClose = () => {
    setShowModal(false);
    window.location.reload();
  }
  
  return (
    <svg
      className={`button-evaluation ${className}`}
      fill="none"
      height="70"
      viewBox="0 0 70 70"
      width="70"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => handleClickReporting('Pas bien du tout')}
      data-toggle="modal" 
      data-target="#exampleModalCenter"
    >
      <rect className="rect" fill="#F9F9F9" height="69.6947" rx="16" width="69.6947" y="-0.0175781" />
      <path
        className="path"
        d="M47.6 9.98458C42.3608 10.009 37.5431 12.8374 35 17.3813C31.8342 11.6691 25.1699 8.81014 18.8062 10.4336C12.442 12.0571 7.99517 17.751 8 24.2697C8 39.6045 26.5256 59.9824 35 59.9824C43.4744 59.9824 62 39.6045 62 24.2697C61.9908 16.3838 55.5488 9.99374 47.6 9.98458ZM35 58.1968C28.25 58.1968 9.8 38.9624 9.8 24.2697C9.79649 18.2414 14.1326 13.0706 20.1083 11.9769C26.0839 10.8835 31.9946 14.1793 34.1593 19.8117C34.2925 20.1574 34.6269 20.3854 35 20.3854C35.3731 20.3854 35.7075 20.1574 35.8407 19.8117C38.0054 14.1793 43.9161 10.8835 49.8917 11.9769C55.8674 13.0706 60.2035 18.2414 60.2 24.2697C60.2 38.9624 41.75 58.1968 35 58.1968Z"
        fill="#FF186B"
      />
      <path
        className="path"
        d="M29.1 27.9824C28.603 27.9824 28.2 28.3551 28.2 28.8158C28.2 30.1964 26.9911 31.3158 25.5 31.3158C24.0089 31.3158 22.8 30.1964 22.8 28.8158C22.8 28.3551 22.397 27.9824 21.9 27.9824C21.403 27.9824 21 28.3551 21 28.8158C21 31.1168 23.0149 32.9824 25.5 32.9824C27.9851 32.9824 30 31.1168 30 28.8158C30 28.3551 29.597 27.9824 29.1 27.9824Z"
        fill="#FF186B"
      />
      <path
        className="path"
        d="M48.1 27.9824C47.603 27.9824 47.2 28.3551 47.2 28.8158C47.2 30.1964 45.9911 31.3158 44.5 31.3158C43.0089 31.3158 41.8 30.1964 41.8 28.8158C41.8 28.3551 41.397 27.9824 40.9 27.9824C40.403 27.9824 40 28.3551 40 28.8158C40 31.1168 42.0149 32.9824 44.5 32.9824C46.9851 32.9824 49 31.1168 49 28.8158C49 28.3551 48.597 27.9824 48.1 27.9824Z"
        fill="#FF186B"
      />
      <path
        className="path"
        d="M35 37.9824C31.6878 37.9868 29.0038 41.1182 29 44.9824C29 45.5347 29.3838 45.9824 29.8571 45.9824C30.3305 45.9824 30.7143 45.5347 30.7143 44.9824C30.7143 42.2207 32.6332 39.9824 35 39.9824C37.3668 39.9824 39.2857 42.2207 39.2857 44.9824C39.2857 45.5347 39.6695 45.9824 40.1429 45.9824C40.6162 45.9824 41 45.5347 41 44.9824C40.9962 41.1182 38.3122 37.9868 35 37.9824Z"
        fill="#FF186B"
      />
        <ModalCentered
        show={showModal}
        onHide={() => onClose()}
        title= 'Félicitations !'
        subtitle= 'Evaluation terminée'
        message='Votre évaluation a bien été prise en compte. Merci !'
      />
    </svg>

  );
};
