import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './style.css';
import { useDispatch } from 'react-redux';
import { ClipLoader, RingLoader } from 'react-spinners';
import { api_url } from '../../../globarvariable';
import { logged } from '../../../actions/logged';

const PSC = () => {

    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const searchParams = new URLSearchParams(window.location.search);
    const code:any = searchParams.get('code');


    useEffect(() => {
      
      const formData = {
        code: code
    };
          setLoading(true)
          fetch(api_url+'api/auth/psc/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
      })
          .then((response) => response.json())
          .then((data) => {
            if(data.access_token){
              localStorage.setItem('accessToken', data.access_token);
              dispatch(logged());
              navigate('/medecin');
            }
            else{
              setError('Impossible de se connecter car le nom d\'utilisateur et le mot de passe sont incorrects');
            }
              
          })
          .catch((error) => {
            console.error('Unaunthenticated', error);
          })


    }, [])


  return (
    <div>
      <div className="form-outline mb-4 login-psc">
           <p>{loading && <ClipLoader color="#123abc" loading={loading} />}</p>
       </div>
    </div>
  )
}

export default PSC