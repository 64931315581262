import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { HeaderMobile } from '../HeaderMobile/HeaderMobile';
import { useNavigate } from 'react-router-dom';
import { api_url } from '../../../globarvariable';
import './style.css'
import axios from 'axios';
import { CardRdv } from '../CardRdv/CardRdv';


const CalendarPage = () => {
  type ValuePiece = Date | null;

 type Value = ValuePiece | [ValuePiece, ValuePiece];
const [value, onChange] = useState<Value>(new Date());

const [selectedDate, setSelectedDate] = useState(new Date());
const navigate = useNavigate();
const accessToken = localStorage.getItem('accessToken')
const [events, setEvents] = useState([]);
const [dateClicked, setDateClicked] = useState<Date>();
const [dataArray, setDataArray] = useState<any[]>([]);
const [sessionDate, setSessionDate] = useState<any[]>([]);
const [activities, setActivities] = useState<any[]>([]);
const [centerName, setCenterName] = useState('');
const [centerAddress, setCenterAddress] = useState('');
const [centerPostal, setCenterPostal] = useState('');
const [sessionDone, setSessionDone] = useState(0);
const [sessionPlanned, setSessionPlanned] = useState(0);
const bottomEl = useRef(null);

const handleNavigate = () => {
    navigate('/patient');
}

const formatDate = (dateString) => {
  // Create a Date object from the input string
  const date = new Date(dateString);

  // Extract the components of the date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  // Format the date part as 'YYYY-MM-DD'
  const formattedDate = `${day}/${month}`;

  return formattedDate;
};

const formatHour = (dateString) => {
  // Create a Date object from the input string
  const date = dateString.split('T');
  const hour = date[1].split(':');

  // Format the date part as 'YYYY-MM-DD'
  const formattedHour = `${hour[0]}:${hour[1]}`;

  return formattedHour;
};

useEffect(() => {

  const response =  axios.get(api_url+'api/appointments/my/stats', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      }).then((response) =>{
        if(response.data.done){
          setSessionDone(response.data.done);
        }
          const total = response.data.undone + response.data.done + response.data.missed;
          setSessionPlanned(total)

    })
  
  }, [])

useEffect(() => {

  const response =  axios.get(api_url+'api/appointments/my', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      }).then((response) =>{
        //setSelectedDate(response.data.appointments);
        const dataString = JSON.stringify(response.data.appointments);
          const dataArray  = JSON.parse(dataString);
          setDataArray(dataArray);
          if(dataArray.length != 0 ){
            const dateArray = dataArray.map(item => {
            return {
                id: item.id,
                date: new Date(item.date)
            }
          }

          );
          setEvents(dateArray)
      }
    })
  
  }, [])

  useEffect(() => {
    const fetchData = () => {
      const response =  axios.get(api_url+'api/prescriptions/my', {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) =>{
         if(response.data.id){
              setCenterName(response.data.patient.center.name)
              setCenterAddress(response.data.patient.center.address)
              setCenterPostal(response.data.patient.center.postal_code +' '+response.data.patient.center.city)

            }
      })
      //
    }


    fetchData();

  }, []);

  



const handleClickDate = (date) => {

      window.scroll({
        top: bottomEl?.current?.scrollHeight,
        behavior: 'smooth',
      });
      if(dataArray.length != 0){
            const dateArray = dataArray.map(item => item.date);
            const filteredDataArray = dataArray.filter(item => {
              const itemDate = new Date(item.date);
              return itemDate.getDate() === date.getDate() &&
                      itemDate.getMonth() === date.getMonth() &&
                      itemDate.getFullYear() === date.getFullYear();
             });
             const updatedDateArray = filteredDataArray.map(item => item.date);
            setSessionDate(updatedDateArray);
            const activity = dataArray.map(item => item.activities);
            setActivities(activity);
        }

}
  
  const tileContent = ({ date, view }) => {
    const eventForDate = events.find(
      (event) =>
        event.date.getFullYear() === date.getFullYear() &&
        event.date.getMonth() === date.getMonth() &&
        event.date.getDate() === date.getDate()
    );
  
    if (eventForDate) {
      return <div style={{ backgroundColor: 'green', borderRadius: '50%', height: '10px', width: '10px' }} />;
    }
  
    return null;
  };
  
  return (
    <div className='calendar-div'>
        <header className='header-goal'>
        <img src='/img/arrow-left.png' onClick={handleNavigate}/>
            <h5> Calendrier </h5>
        </header>
        <div className='container' ref={bottomEl}>
          <div className='row'>
              <div className='col-6 col-sm-6 session'><p className='done'>Séances terminées <br/> <h5>{ sessionDone }</h5></p></div>
              <div className='col-6 col-sm-6 session'><p className='planified'>Séances programmées <br/> <h5>{ sessionPlanned }</h5></p></div>
          </div>
        <Calendar 
          onClickDay={(e) => handleClickDate(e)}
          onChange={onChange}
          value={selectedDate}
          tileContent={tileContent}
        />
   {sessionDate.length !==0 && sessionDate.map((date, item) => <CardRdv className="card-RDV-instance calendar" vector="/img/vector-1.svg"  date={formatDate(date)} hour={formatHour(date)} activities={activities} name={centerName} address={centerAddress} postal={centerPostal}/> )}

        </div>
    </div>
  );
};

export default CalendarPage;
