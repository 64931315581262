import React, { useEffect, useState } from "react";
import { ButtonAction } from "../../components/Patients/ButtonAction";
import { HeaderMobile } from "../../components/Patients/HeaderMobile";
import { HomeIndicator } from "../../components/Patients/HomeIndicator";
import { TextFieldBasic } from "../../components/Patients/TextFieldBasic";
import "./style.css";
import PasswordGenerator from "../../components/Patients/PasswordGenerator/PasswordGenerator";
import axios from "axios";
import { api_url } from "../../globarvariable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import ModalCentered from "../../components/Patients/Modal/Modal";

export const PasswordEapa = (): JSX.Element => {

  const[stateButton, setStateButton] = useState('disabled');
  const[lastName, setLastName] = useState('');
  const[firstName, setFirstName] = useState('');
  const[email, setEmail] = useState('');
  const[error, setError] = useState(false);
  const[errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [childOfChildValue, setChildOfChildValue] = useState('');
  const userId = useSelector((action : any) => action.userIdReducer);
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cgu, setCgu] = useState(false);
  const [confidentialite, setConfidentialite] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [valueCenter, setValueCentre] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [data, setData] = useState([]);
  const [centerId, setCenterId] = useState('');
  const idEapa = localStorage.getItem('idEapa');

  const handleChildOfChildValueChange = (value) => {
    setChildOfChildValue(value);
    setPassword(value);
  };

  const handleSubmit =  async () => {
    setLoading(true);
    const formData = {
      password: password
    };

    fetch(api_url+'api/users/me', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.id){
          setTimeout(() => {
            setLoading(false);
          }, 2000);
          setShowModal(true);
        }
        else{
            setError(true);
            setErrorMessage(data.detail)
        }
      })
      .catch((error) => {
        console.error('Error inserting data:', error);
      });

  }

  useEffect(() => {

    if(password != ''){
      setIsDisabled(false);
    }

  }, [password]);


  const onClose = () => {
     setShowModal(false);
      navigate('/login-eapa');
  }

  return (
    <div className="inscription-mobile eapa">
      <div className="div-3 mot-de-passe-div">
        <p className="p inscription-completer">Pour completer votre inscription, créer un mot de passe fort</p>
        <div className="text-wrapper-3 mot-de-passe">Votre mot de passe</div>
        <PasswordGenerator handleValueChange={handleChildOfChildValueChange}/>
        <button
          className="button-action-instance sign-up btn btn-primary valider-mot-de-passe"
          color="dark"
          onClick={handleSubmit}
          type="button"
          disabled={isDisabled}> Continuer </button>
        {loading && <ClipLoader className='loader' color="#123abc" loading={loading} />}

        <HeaderMobile className="header-mobile-instance header-password-eapa" logoPropertyDefault="/img/logo-1.svg" type="logo" />
      </div>
      <ModalCentered
        show={showModal}
        onHide={() => onClose()}
        title= 'Félicitations !'
        subtitle= 'Inscription réussie'
        message='Votre compte a été créér avec succès. Vous recevrez un email lorsque votre compte sera activé'
      />
    </div>
  );
};
