import React, { useEffect, useState } from 'react'
import { Html5QrcodeScanner } from 'html5-qrcode'
import { api_url } from '../../globarvariable';
import QrCodePlugin from '../../components/EAPA/QrCode/QrCodePlugin';
import ModalCentered from '../../components/Patients/Modal/Modal';
import './style.css';
import { HeaderMobile } from '../../components/Patients/HeaderMobile/HeaderMobile';
import { IconArrowArrow } from '../../components/Patients/IconArrowArrow';

const Eapa = () => {

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [scanResult, setScanResult] = useState(null);
  const [patient, setPatient] = useState(null);
  const [data, setData] = useState(null);
  const [formattedDate, setFormattedDate] =  useState('');
  const [error, setError] = useState('');
  const accessToken = localStorage.getItem('accessToken');
  const[showModal, setShowmodal] = useState(false);
  const [showScan, setShowScan] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };

    const formatted = currentDate.toLocaleDateString('fr-FR', options);
    setFormattedDate(formatted);

  }, [])
  useEffect(() => {

  }, [showScan]);

  useEffect(() => {
    const formData = {
      validate_key: scanResult,
    };
    if (scanResult !== null) {
    fetch(api_url+'api/appointments/validate/'+scanResult, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } 
      else {
        // Handle the case where the response is not OK (e.g., error handling)
        throw new Error('Il ya une erreur lors du SCAN');
      }

    })
    .then((data) => {
      setData(data);
        if(data.patient){
          setPatient(data.patient)
          setShowSuccess(true)
        }
    })
    .catch((error) => {
      setShowError(true);
    });
  }
  }, [scanResult])

const tryAgain = () => {
  //window.location.reload();
  setShowmodal(true);
}

const onClose = () => {
  setShowmodal(false);
  window.location.reload();
}

const handleShowScan = () => {
  setShowScan(true);
    setVisible(!visible);
    const scanner = new Html5QrcodeScanner('reader', {
      qrbox: {
        width: 150,
        height: 150,
      },
      fps: 5
      }, 
     true);

     scanner.render(succes, error);

     function succes(result) {
         setScanResult(result);
         if(scanResult){
          setShowSuccess(true)
            scanner.clear();
         }
     }
     function error(err){
       console.log(err);
     }
}

  return (
    <div className='app'>
        <HeaderMobile
          className="header-mobile-instance"
          iconTimeCalendarIconTimeCalendarClassName="header-mobile-2"
          logoPropertyDefault="/img/logo.svg"
          type="logo-calendar-profile"
        />
        {showSuccess === false &&  showError === false  && <div className='welcome-eapa'>
        <div className="text-wrapper-5">Bienvenue sur APAORA ! </div>
        <p className='date'><strong>{formattedDate}</strong></p>
        <p className="p">Scanner le QR Code fourni par votre patient pour valider une séance</p>
        <IconArrowArrow className="icon-arrow-arrow-down" />
        </div> }

        {showScan === false && <p className='scanner'><button className='btn btn-primary' onClick={handleShowScan}> <img src='/img/scan_qr_code.png' /> Scanner </button></p>}

        {showSuccess === false && showError === false  &&
    <div id='reader'  style={{display: visible ? 'block' : 'none'}}>  
    </div>
}

    {error !== '' && <p>{error}</p>}

    {showSuccess === true && patient !== null && <div className='scan-success'>
        <p className='date'><strong>{formattedDate}</strong></p>
        <span className='address-center'>{patient.center.address}<br/> {patient.center.postal_code} {patient.center.city}</span>
        <p className='image'><img src='/img/correct.png' /></p>
        <p className='session'>La séance de <strong> {patient.first_name} {patient.last_name}</strong> a bien été validée</p>
        <p><a href="/eapa">Retour à l'accueil</a></p>
        <div className='footer'>
            <p>Un problème ? <br/> Pour toute question veuillez contacter notre support par e-mail:</p>
            <p className='support'>support@observance.care  <img src='/img/message-3.png' /></p>
        </div>
    </div>}

    {showError === true && <div className='scan-success'>
        <p className='date'><strong>{formattedDate}</strong></p>
        <p className='image'><img src='/img/false.png' /></p>
        <p className='sessionwrong'><strong>Ce QR Code n'est pas valide</strong></p>
        <p className='details'>Nous ne pouvons pas valider la séance </p>
        <p className='try' onClick={tryAgain}>Regénérer un QR Code</p>
        <p><a href="/eapa">Retour à l'accueil</a></p>
        <div className='footer'>
            <p>Un problème ? <br/> Pour toute question veuillez contacter notre support par e-mail:</p>
            <a className='support' href="mailto:support@observance.care ">support@observance.care  <img src='/img/message-3.png' /></a>
        </div>
    </div>}

    <ModalCentered
        show={showModal}
        onHide={() => onClose()}
        title= 'QR Code réinitialisé'
        subtitle= ''
        message='Veuillez réactualisez le QR Code du patient et flashez à nouveau'
      />
  

    </div>
  )
}

export default Eapa